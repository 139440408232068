import classnames from 'classnames';
import Modal from 'react-modal';

export default function Cart({ show, onHide, settings, user, betAmount, onChangeBetAmount, calculatePrize, finishBet, selectedOdds, onRemoveOdd, onCleanOdds }) {
  return (
    <Modal
      isOpen={show}
      className="modal"
      overlayClassName="modal-overlay modal-overlay--floating modal-overlay-cart"
      bodyOpenClassName="modal"
    >
      <header className="modal-header">
        <div className="modal-header__left">
          <h1 className="title-2 n150">Carrinho</h1>
        </div>
        <button className="display-grid">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" style={{ fill: 'var(--t310)'}}></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </button>
      </header>
      <div className="wrap-cart">
        <div className="cart">
          <div className="header-title">
            Carrinho
            {selectedOdds.length > 0 && <span> · {selectedOdds.length} aposta{selectedOdds.length > 1 ? 's' : ''}</span>}
            <div className="close" onClick={() => onHide()}>X</div>
          </div>
          <div className="header">
            <span className={selectedOdds.length <= 1 ? 'selected' : ''}>Simples</span>
            <span className={selectedOdds.length > 1 ? 'selected' : ''}>Combinada</span>
          </div>
          <div className="itens">
            {selectedOdds.map(odd => (
              <div className="item" key={`${odd.match.id_partida}-${odd.odd.id_tipo}`}>
                <div className="left">
                  <div className="team">{odd.odd.tipo}</div>
                  <div className="winner">
                    <span>Tipo de aposta:</span>
                    <span>{odd.odd.categoria}</span>
                  </div>
                  <div className="description">
                    {odd.match.mandante}
                    <br />
                    {odd.match.visitante}
                  </div>
                </div>
                <div className="right">
                  <div className="odds">{odd.odd.cotacao}</div>
                  <div className="delete" onClick={() => onRemoveOdd(odd.match, odd.odd, odd.live)}>X</div>
                  {selectedOdds.length === 1 && <>
                    <div className="qty">
                      <input value={betAmount} onChange={({ target }) => onChangeBetAmount(target.value)} />
                    </div>
                    <div className="description">
                      Prêmio: 
                      <span>{calculatePrize().toFixed(2).replace('.', ',')}</span>
                    </div>
                  </>}
                </div>
              </div>
            ))}
          </div>
          <div className="cart__footer">
            <div className="values">
              <span className="input-wrap">
                <span className="title">
                  Valor da aposta <span>({selectedOdds.length} aposta{selectedOdds.length > 1 ? 's' : ''} )</span>
                </span>
                <input value={betAmount} onChange={({ target }) => onChangeBetAmount(target.value)} />
              </span>
            </div>
            <div className="summary">
              <div>
                Valor total <span>{settings.habilitar_centavos ? betAmount : betAmount.toFixed(2).replace('.', ',')}</span>
              </div>
              <div>
                Provável Prêmio <span>{calculatePrize().toFixed(2).replace('.', ',')}</span>
              </div>
            </div>
            <div className="actions">
              <button className="button-flat button-flat-10" onClick={() => onCleanOdds()}>
                <span className="button-flat-10__title caption-2 lt010">Limpar</span>
              </button>
              <button className={classnames('button-flat button-flat-8', { 'button-flat-8--disabled': betAmount === 0 || user === null })} onClick={() => finishBet()} disabled={betAmount === 0 || user === null}>
                <span className="button-flat-8__title caption-2 lt010">Efetuar aposta</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
