import { differenceInMinutes } from 'date-fns'
import { getLocalDate, normalize } from '../utils'

export function isMainOdd(odd) {
  return [1, 2, 3, 2360, 2361, 2405, 2406, 2407, 2408, 2409, 2410, 2621, 2622].includes(+odd.id_tipo)
}

export function generateMatch(match, settings, live = false) {
  const suffix = live ? 'ao_vivo' : 'pre_jogo'
  const statusKey = `status_${suffix}`
  const adjustKey = `ajuste_${suffix}`

  const cotacoes = match.cotacoes.filter(c => {
    return settings.categorias[c.cat_id]
      && settings.categorias[c.cat_id].tipos[c.id_tipo]
      && settings.categorias[c.cat_id][statusKey] === 1
  }).map(c => {
    const oddCategory = settings.categorias[c.cat_id]
    const maxValue = oddCategory.tipos[c.id_tipo].cotacao_maxima.toFixed(2)
    const adjust = oddCategory.tipos[c.id_tipo][adjustKey] / 100
    let oddValue = (c.cotacao * adjust).toFixed(2)
    c.categoria = oddCategory.titulo
    const favoriteTypes = {
      1: [1, 2],
      4: [14, 15],
      16: [52, 54],
      64: [2284, 2285]
    };

    if (favoriteTypes[c.cat_id] && favoriteTypes[c.cat_id].indexOf(c.id_tipo) !== -1) {
      const allowedTypes = favoriteTypes[c.cat_id]
      const odds = match.cotacoes.filter(cat => cat.cat_id === c.cat_id && allowedTypes.indexOf(cat.id_tipo) !== -1)
      odds.sort((a, b) => b.cotacao - a.cotacao)
      const type = c.cotacao === odds[0].cotacao ? 'zebra' : 'favorito'
      oddValue = (+oddValue * settings[`cotacao_${type}`] / 100).toFixed(2)
    }

    c.cotacao = +oddValue > maxValue ? maxValue : oddValue
    return c
  })

  const [homeOdd, awayOdd, drawOdd] = cotacoes
    .filter(o => isMainOdd(o))
    .sort((a, b) => a.id_tipo - b.id_tipo)

  if (!match.chave) {
    match.chave = [
      match.pais, match.campeonato, match.mandante, match.visitante
    ].join('/')
  }

  const chave = normalize(match.chave)

  return Object.assign(match, { homeOdd, awayOdd, drawOdd, chave, cotacoes })
}

export function generateMatches(matches, settings, live = false) {
  if (live)
    matches = matches.filter(m => m.tempo_jogo < settings.tempo_bloqueio_ao_vivo)

  matches = matches.map(match => generateMatch(match, settings, live))

  if (!live) {
    matches = matches.filter((m) => {
      const start = new Date()
      const end = getLocalDate(m.data, settings.fuso_horario)
      const minutes = differenceInMinutes(end, start)

      return minutes > 0;
    })
  }

  return matches;
}

export function generateOdds(match, live = false, categories = []) {
  const matchTimes = {
    fullTime: { id: 3, categories: {} },
    firstTime: { id: 1, categories: {} },
    secondTime: { id: 2, categories: {} },
  }
  const odds = match.cotacoes

  odds.forEach((odd) => {
    for (let timeKey in matchTimes) {
      const timeData = matchTimes[timeKey]

      if (!live && timeData.id !== odd.tempo_partida) continue
      if (live && timeKey !== 'fullTime') continue
      if (!(odd.cat_id in timeData.categories)) {
        if (live && categories.length > 0) {
          odd.ordem_cat = categories.filter(category => category.titulo === odd.categoria)[0].ordem;
        }

        timeData.categories[odd.cat_id] = {
          id: odd.cat_id,
          name: odd.categoria,
          odds: [],
          ordem: odd.ordem_cat
        }
      }

      timeData.categories[odd.cat_id].odds.push(odd)
      break
    }
  })

  const oddsData = {
    fullTime: Object.values(matchTimes.fullTime.categories).sort((a, b) => a.ordem - b.ordem),
    firstTime: Object.values(matchTimes.firstTime.categories).sort((a, b) => a.ordem - b.ordem),
    secondTime: Object.values(matchTimes.secondTime.categories).sort((a, b) => a.ordem - b.ordem),
    players: match.jogadores,
  }

  return oddsData
}
