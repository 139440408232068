import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import classnames from 'classnames';

import Cart from '../components/layout/Cart';
import Banner1 from '../assets/banners/banner 1.jpg'
import Banner2 from '../assets/banners/banner 2.jpg'
import Banner3 from '../assets/banners/banner 3.jpg'
import Banner4 from '../assets/banners/banner 4.jpg'

export default function Index({ apiUrl, settings, user, isMobile, betAmount, onChangeBetAmount, calculatePrize, finishBet, selectedOdds, handleOddClick, onCleanOdds }) {
  const [favoriteChampionships, setFavoriteChampionships] = useState([])
  const [slide, setSlide] = useState(0)

  useEffect(() => {
    const formData = new FormData();
    formData.append('chave', '')
    formData.append('tipo', '')
    formData.append('data', '')
    formData.append('esporte', 1)
    formData.append('principal', 1)

    fetch(`${apiUrl}/api/jogos`, {
      method: 'POST',
      body: formData
    })
      .then((res) => res.json())
      .then((data) => {
        const championships = data
          .filter((championship) => championship.favorito && +championship.favorito)
          .sort((a, b) => {
            if (a.data < b.data) {
              return -1
            }

            if (a.data > b.data) {
              return 1
            }

            return 0
          })

        setFavoriteChampionships(championships)
      })
  }, [apiUrl, setFavoriteChampionships])

  if (isMobile) {
    return (
      <>
        <Helmet>
          <title>{settings.nome}</title>
          <meta name="keywords" content={settings.chaves} />
          <meta name="description" content={settings.descricao} />
          <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.11.0/css/all.css" />
        </Helmet>
        <div className="country-filter">
          <div className="form-control">
            <label className="input-label select">
              <Select
                options={[{ value: 'todos-paises', label: 'Todos os Países' }]}
                value={{ value: 'todos-paises', label: 'Todos os Países' }}
                onChange={(option) => { }}
                className="input-select"
                classNamePrefix="input-select"
                placeholder="Todos os Países"
                isSearchable={false}
              />
              <p className="input-label__label caption-3 dt020">País </p>
            </label>
          </div>
          <div className="form-control">
            <label className="input-label select">
              <Select
                options={[{ value: 'todos-campeonatos', label: 'Todos os Campeonatos' }]}
                value={{ value: 'todos-campeonatos', label: 'Todos os Campeonatos' }}
                onChange={(option) => { }}
                className="input-select"
                classNamePrefix="input-select"
                placeholder="Todos os Campeonatos"
                isSearchable={false}
              />
              <p className="input-label__label caption-3 dt020">Torneio </p>
            </label>
          </div>
        </div>
        <ul className="weekday-filter expand">
          <li className="weekday-filter__item selected">
            <a href="/">Todos<span></span></a>
          </li>
          <li className="weekday-filter__item">
            <a href="/">Hoje<span>13 mai</span></a>
          </li>
          <li className="weekday-filter__item">
            <a href="/">Amanhã<span>14 mai</span></a>
          </li>
          <li className="weekday-filter__item">
            <a href="/">sábado<span>15 mai</span></a>
          </li>
          <li className="weekday-filter__item">
            <a href="/">domingo<span>16 mai</span></a>
          </li>
        </ul>
        <div className="wrap-content" style={{ minHeight: '120vh' }}>
          <div>
            <div className="new-layout">
              <div className="home container">
                {favoriteChampionships.length > 0 ? (
                  <section className="eventwrap">
                    <div className="eventwrap__head showing-sport-icon">
                      <div className="eventwrap__title__icon eventwrap__title__icon sport-icon-menu__futebol"></div>
                      <div className="section-description">
                        <h2>Próximos eventos</h2>
                      </div>
                    </div>
                    <div className="eventwrap__labels--mobile">
                      <div className="eventwrap__labels--mobile_tree">
                        <span></span>
                        <span className="label">Casa</span>
                        <span className="label">Empate</span>
                        <span className="label">Fora</span>
                      </div>
                    </div>
                    <ul className="eventos-list shadow-box-mobile">
                      {favoriteChampionships.map(match => (
                        <li className="eventos-list__item">
                          <a className="eventos-item" href={`/sports/soccer/${match.id_campeonato}/${match.id_partida}`}>
                            <div className="momento-info">
                              <p className="eventos-item__date caption-2">
                                <span className="evento-item_minutos"></span>
                                <span>
                                  <span className="evento-item_minutos__data-tempo">Hoje · 19:30</span>
                                </span>
                              </p>
                            </div>
                            <p className="eventos-item__title subhead-demi truncate" title="Red Bull Bragantino x Palmeiras">
                              {match.mandante}
                              <br />
                              {match.visitante}
                            </p>
                          </a>
                          <div className="eventos-list__item__odds">
                            <div className="eventos-list__item__odds-wrap">
                              {['homeOdd', 'drawOdd', 'awayOdd'].map(oddType => {
                                const odd = match[oddType]
                                const isDisabled = +odd.cotacao <= 1.0

                                return (
                                  <div className="sub-event" key={odd.id_tipo}>
                                    <button
                                      className={classnames('button-odd', { 'button-odd--disabled': isDisabled, 'button-odd--active': selectedOdds.find(item => item.match.id_partida === match.id_partida && item.odd.id_tipo === odd.id_tipo) })}
                                      title={`${odd.tipo} ${odd.cotacao}`}
                                      onClick={() => handleOddClick(match, odd)}
                                      disabled={isDisabled}
                                    >
                                      <span className={classnames('button-odd-default', { 'cotacao-zero': isDisabled })}>
                                        <span className="button-odd-default__info subhead dt010">
                                          {isDisabled ? <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"></path>
                                          </svg> : odd.cotacao}
                                        </span>
                                      </span>
                                    </button>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </li>
                      ))}
                      <li className="eventos-list__footer">
                        <a href="/aovivo?esporte=futebol">
                          <span>Todos os eventos:</span>
                          <span>{favoriteChampionships.length}
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 320 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                              <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                            </svg>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </section>
                ) : null}
                <span className="" style={{ display: 'inline-block' }}></span>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>{settings.nome}</title>
        <meta name="keywords" content={settings.chaves} />
        <meta name="description" content={settings.descricao} />
        <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.11.0/css/all.css" />
      </Helmet>
      <div>
        <div className="new-layout">
          <div className="menu-left__wrap">
            <div className="menu-left">
              <div className="menu-left__item menu-left__item--highlight">
                <a className="menu-left__item__action" href="/como-funciona">
                  <span className="menu-left__item__action__title">Como funciona</span>
                </a>
              </div>
              {favoriteChampionships.length > 0 ? (
                <ul className="menu-left__group">
                  <li className="menu-left__item menu-left__item--grouphead">
                    <div className="menu-left__item__action">
                      <span className="menu-left__item__action__title">Especiais</span>
                    </div>
                  </li>
                  {favoriteChampionships.map(match => (
                    <li className="menu-left__item menu-left__item--groupitem">
                      <a className="links-destaques--item" href={`/sports/${/* match.esporte */ 'soccer'}/${match.id_campeonato}`}>
                        <span>{match.campeonato}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              ) : null}
              <ul className="menu-left__group">
                <li className="menu-left__item menu-left__item--grouphead">
                  <div className="menu-left__item__action">
                    <span className="menu-left__item__action__title">Esportes</span>
                  </div>
                </li>
                <li className="menu-left__item menu-left__item--groupitem  level-1" data-id="4,0">
                  <div className="sport-icon-menu sport-icon-menu__futebol"></div>
                  <a className="menu-left__item__action" href="/sports/soccer">
                    <span className="menu-left__item__action__title">Futebol</span>
                  </a>
                  <div className="menu-left__item__toggle">
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                    </svg>
                  </div>
                </li>
                <li className="menu-left__item menu-left__item--groupitem  level-1" data-id="4,3">
                  <div className="sport-icon-menu sport-icon-menu__basquete"></div>
                  <a className="menu-left__item__action" href="/sports/basquete">
                    <span className="menu-left__item__action__title">Basquete</span>
                  </a>
                  <div className="menu-left__item__toggle">
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                    </svg>
                  </div>
                </li>
                <li className="menu-left__item menu-left__item--groupitem  level-1" data-id="4,5">
                  <div className="sport-icon-menu sport-icon-menu__boxe"></div>
                  <a className="menu-left__item__action" href="/sports/boxe">
                    <span className="menu-left__item__action__title">Lutas</span>
                  </a>
                  <div className="menu-left__item__toggle">
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                    </svg>
                  </div>
                </li>
                <li className="menu-left__item menu-left__item--groupitem  level-1" data-id="4,9">
                  <div className="sport-icon-menu sport-icon-menu__hoquei"></div>
                  <a className="menu-left__item__action" href="/esportes/hoquei">
                    <span className="menu-left__item__action__title">Hóquei</span>
                  </a>
                  <div className="menu-left__item__toggle">
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                    </svg>
                  </div>
                </li>
                <li className="menu-left__item menu-left__item--groupitem  level-1" data-id="4,2">
                  <div className="sport-icon-menu sport-icon-menu__voleibol"></div>
                  <a className="menu-left__item__action" href="/esportes/voleibol">
                    <span className="menu-left__item__action__title">Vôleibol</span>
                  </a>
                  <div className="menu-left__item__toggle">
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                    </svg>
                  </div>
                </li>
                <li className="menu-left__item menu-left__item--groupitem  level-1" data-id="4,4">
                  <div className="sport-icon-menu sport-icon-menu__tenis"></div>
                  <a className="menu-left__item__action" href="/esportes/tenis">
                    <span className="menu-left__item__action__title">Tênis</span>
                  </a>
                  <div className="menu-left__item__toggle">
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                    </svg>
                  </div>
                </li>
                <li className="menu-left__item menu-left__item--groupitem  level-1" data-id="4,12">
                  <div className="sport-icon-menu sport-icon-menu__league-of-legends"></div>
                  <a className="menu-left__item__action" href="/esportes/league-of-legends">
                    <span className="menu-left__item__action__title">E-Sports</span>
                  </a>
                  <div className="menu-left__item__toggle">
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                    </svg>
                  </div>
                </li>
              </ul>
              <ul className="menu-left__group">
                <li className="menu-left__item menu-left__item--grouphead">
                  <div className="menu-left__item__action">
                    <span className="menu-left__item__action__title">Institucionais</span>
                  </div>
                </li>
                <li className="menu-left__item menu-left__item--groupitem"><div className="sport-icon-menu sport-icon-menu__quem_somos"></div><a className="menu-left__item__action" href="/quem-somos"><span className="menu-left__item__action__title">Quem somos</span></a></li>
                <li className="menu-left__item menu-left__item--groupitem"><div className="sport-icon-menu sport-icon-menu__ajuda"></div><a className="menu-left__item__action" href="/ajuda"><span className="menu-left__item__action__title">Ajuda</span></a></li>
                <li className="menu-left__item menu-left__item--groupitem"><div className="sport-icon-menu sport-icon-menu__regras"></div><a className="menu-left__item__action" href="/regras"><span className="menu-left__item__action__title">Regras</span></a></li>
              </ul>
            </div>
          </div>
          <div className="home container">
            <div>
              <div className="carousel-root banner-carousel " tabIndex="0">
                <div className="carousel carousel-slider" style={{ width: '100%' }}>
                  <button type="button" aria-label="previous slide / item" className="control-arrow control-prev" onClick={() => setSlide(slide - 1 % 3)}></button>
                  <div className="slider-wrapper axis-horizontal" style={{ height: '285px' }}>
                    <ul className="slider animated" style={{ transform: `translate3d(${slide * -100}%, 0px, 0px)`, height: '285px', transitionDuration: '350ms' }}>
                      <li className="slide selected">
                        <div>
                          <Link to="/opcoes/deposito?promocode=BONUSBOASVINDAS">
                            <div title="bonus" className="banner-image" style={{ backgroundImage: `url("${Banner1}")` }}></div>
                          </Link>
                        </div>
                      </li>
                      <li className="slide">
                        <div>
                          <a href="https://www.betsul.com/esportes/futebol/inglaterra/premier-league">
                            <div title="premier-league" className="banner-image" style={{ backgroundImage: `url("${Banner2}")` }}></div>
                          </a>
                        </div>
                      </li>
                      <li className="slide">
                        <div>
                          <a href="https://www.betsul.com/esportes/futebol/espanha/la-liga">
                            <div title="la-liga" className="banner-image" style={{ backgroundImage: `url("${Banner3}")` }}></div>
                          </a>
                        </div>
                      </li>
                      <li className="slide">
                        <div>
                          <a href="https://www.betsul.com/esportes/futebol/internacional/copa-libertadores">
                            <div title="libertadores" className="banner-image" style={{ backgroundImage: `url("${Banner4}")` }}></div>
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <button type="button" aria-label="next slide / item" className="control-arrow control-next" onClick={() => setSlide(slide + 1 % 4)}></button>
                </div>
              </div>
            </div>
            <div className="icon-sub-menu">
              <div className="itens">
                <a href="/?esporte=futebol" className="item selected">
                  <div className="title">
                    <p>Futebol</p>
                  </div>
                  <div className="img futebol"></div>
                  <div className="marker"></div>
                </a>
                <a href="/?esporte=basquete" className="item">
                  <div className="title">
                    <p>Basquete</p>
                  </div>
                  <div className="img basquete"></div>
                  <div className="marker"></div>
                </a>
                <a href="/?esporte=boxe" className="item">
                  <div className="title">
                    <p>Lutas</p>
                  </div>
                  <div className="img boxe"></div>
                  <div className="marker"></div>
                </a>
                <a href="/?esporte=hoquei" className="item">
                  <div className="title">
                    <p>Hóquei</p>
                  </div>
                  <div className="img hoquei"></div>
                  <div className="marker"></div>
                </a>
                <a href="/?esporte=voleibol" className="item">
                  <div className="title">
                    <p>Vôleibol</p>
                  </div>
                  <div className="img voleibol"></div>
                  <div className="marker"></div>
                </a>
                <a href="/?esporte=tenis" className="item">
                  <div className="title">
                    <p>Tênis</p>
                  </div>
                  <div className="img tenis"></div>
                  <div className="marker"></div>
                </a>

                <a href="/?esporte=league-of-legends" className="item">
                  <div className="title">
                    <p>E-Sports</p>
                  </div>
                  <div className="img league-of-legends"></div>
                  <div className="marker"></div>
                </a>
              </div>
            </div>
            {favoriteChampionships.length > 0 ? (
              <section className="eventwrap">
                <div className="eventwrap__head">
                  <div className="section-description">
                    <h2>Próximos eventos</h2>
                  </div>
                  <div className="eventwrap__labels">
                    <span className="label">Casa</span>
                    <span className="label">Empate</span>
                    <span className="label">Fora</span>
                  </div>
                </div>
                <ul className="eventos-list shadow-box-mobile">
                  {favoriteChampionships.map(match => (
                    <li className="eventos-list__item">
                      <a className="eventos-item" href={`/sports/${/*match.esporte*/ 'soccer'}/${match.id_campeonato}/${match.id_partida}`}>
                        <div className="momento-info">
                          <p className="eventos-item__date caption-2">
                            <span className="evento-item_minutos"></span>
                            <span>
                              <span className="evento-item_minutos__data-tempo">Hoje<br />19:30</span>
                            </span>
                          </p>
                        </div>
                        <p className="eventos-item__title subhead-demi truncate" title="Red Bull Bragantino x Palmeiras">
                          {match.mandante}
                          <br />
                          {match.visitante}
                        </p>
                      </a>
                      <div className="eventos-list__item__odds">
                        <span className="cursor-pointer">
                          <i className="icon-statics"></i>
                        </span>
                        <div className="eventos-list__item__odds-wrap">
                          {['homeOdd', 'drawOdd', 'awayOdd'].map(oddType => {
                            const odd = match[oddType]
                            const isDisabled = +odd.cotacao <= 1.0

                            return (
                              <div className="sub-event" key={odd.id_tipo}>
                                <button
                                  className={classnames('button-odd', { 'button-odd--disabled': isDisabled, 'button-odd--active': selectedOdds.find(item => item.match.id_partida === match.id_partida && item.odd.id_tipo === odd.id_tipo) })}
                                  title={`${odd.tipo} ${odd.cotacao}`}
                                  onClick={() => handleOddClick(match, odd)}
                                  disabled={isDisabled}
                                >
                                  <span className={classnames('button-odd-default', { 'cotacao-zero': isDisabled })}>
                                    <span className="button-odd-default__info subhead dt010">
                                      {isDisabled ? <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"></path>
                                      </svg> : odd.cotacao}
                                    </span>
                                  </span>
                                </button>
                              </div>
                            )
                          })}
                        </div>
                        <a className="button-odd button-odd--plus eventos-list__item__odds__plus button-link" href={`/sports/soccer/${match.id_campeonato}/${match.id_partida}`} title="Ver mais odds">
                          <span className="button-odd-plus__title subhead dt030">+</span>
                        </a>
                      </div>
                    </li>
                  ))}
                </ul>
              </section>
            ) : null}
          </div>
          <Cart
            settings={settings}
            user={user}
            betAmount={betAmount}
            onChangeBetAmount={onChangeBetAmount}
            calculatePrize={calculatePrize}
            finishBet={finishBet}
            odds={selectedOdds}
            onRemoveOdd={handleOddClick}
            onCleanOdds={onCleanOdds}
          />
        </div>
      </div>
    </>
  )
}
