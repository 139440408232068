import { useCallback, useEffect, useState } from 'react';
import { Link } from "react-router-dom";

export default function Extract({ apiUrl, isMobile, user }) {
  const today = new Date().toISOString().split('T')[0]
  const token = localStorage.getItem('token')
  const [startDate, setStartDate] = useState(today)
  const [finalDate, setFinalDate] = useState(today)
  const [showPreviousBalance, setShowPreviousBalance] = useState(false)
  const [needPreviousBalance, setNeedPreviousBalance] = useState(false)
  const [wasRequested, setWasRequested] = useState(false)
  const [betsAmout, setBetsAmount] = useState(0)
  const [prizes, setPrizes] = useState(0)
  const [previousBalance, setPreviousBalance] = useState(0)
  const [balance, setBalance] = useState(0)
  const [transactions, setTransactions] = useState([])

  const loadTransactions = useCallback(() => {
    fetch(`${apiUrl}/api/movimentacoes`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${token}`
      },
      credentials: 'include',
      body: new URLSearchParams({
        inicio: startDate,
        fim: finalDate,
      })
    })
    .then((res) => res.json())
    .then((data) => setTransactions(data))
  }, [apiUrl, token, startDate, finalDate])

  const loadExtract = useCallback(() => {
    fetch(`${apiUrl}/api/extrato`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${token}`
      },
      body: new URLSearchParams({
        inicio: startDate,
        fim: finalDate,
        saldo_anterior: +showPreviousBalance
      })
    })
    .then((res) => res.json())
    .then(async (data) => {
      await loadTransactions()
      setBetsAmount(parseFloat(data.entradas || 0))
      setPrizes(parseFloat(data.saidas || 0))
      setPreviousBalance(data.saldo_anterior || 0)
      setBalance(data.saldo || (user && user.saldo))
      setWasRequested(true)
    })
  }, [apiUrl, token, startDate, finalDate, loadTransactions, showPreviousBalance, user])

  useEffect(() => {
    loadExtract()
  }, [loadExtract])

  const handleSubmit = (event) => {
    event.preventDefault()
    setNeedPreviousBalance(showPreviousBalance)

    loadExtract()
  }

  const renderFilter = () => (
    <form className={`extrato__filter ${!isMobile ? 'shadow-on-hover' : ''}`} onSubmit={handleSubmit}>
      <label className="input-label">
        <input className="input" type="date" label="Data inicial" name="dataInicio" placeholder="" value={startDate} onChange={({target}) => setStartDate(target.value)} />
        <p className="input-label__label caption-3 dt020">Data inicial</p>
      </label>
      <label className="input-label">
        <input className="input" type="date" label="Data final" name="dataFim" placeholder="" value={finalDate} onChange={({target}) => setFinalDate(target.value)} />
        <p className="input-label__label caption-3 dt020">Data final</p>
      </label>
      <label className={`input-label checkbox-label${showPreviousBalance ? ' checked' : ''}`} onClick={() => setShowPreviousBalance(!showPreviousBalance)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
          {showPreviousBalance ? <>
            <path d="M0 0h24v24H0z" fill="none"></path>
            <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" style={{fill: 'var(--t310)'}}></path>
          </>
          :
          <>
            <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" style={{fill: 'var(--dt010)'}}></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </>}
        </svg>
        <p className="input-label__label caption-3 dt020">Usar Saldo Anterior</p>
      </label>
      <button className="button-flat button-flat-6">
        <span className="button-flat-6__title caption-2 lt010">Consultar extrato</span>
      </button>
    </form>
  )

  const renderTable = () => {
    if (isMobile) 
      return (
        <div className="extrato-table-relative-wrapper">
          <div className="extrato-table-wrapper">
            <div className="extrato-header extrato-item">
              <div className="extrato-content">
                <div>Data</div>
                <div>Lançamento</div>
                <div>Saldo</div>
              </div>
            </div>
            {needPreviousBalance && <div className="extrato-normal extrato-item">
              <div className="extrato-content">
                <div>{new Date(startDate).toLocaleDateString()}</div>
                <div>Saldo anterior</div>
                <div className="text-right">{previousBalance}</div>
              </div>
            </div>}
            {transactions && transactions.map((transaction) => (
              <div key={transaction.id} className="">
                <div className="extrato-content">
                  <div>{new Date(transaction.data).toLocaleDateString()}</div>
                  <div>
                    <div className="extrato-description">{transaction.descricao}</div>
                  </div>
                  <div className="text-right">{transaction.valor.replace('.', ',')}</div>
                </div>
              </div>
            ))}
          </div>
          <div className="extrato-table__footer">
            <p className="callout dt010">Total ganho</p>
            <p className="callout dt010">{prizes}</p>
          </div>
          <div className="extrato-table__footer">
            <p className="callout dt010">Total apostado</p>
            <p className="callout dt010">{betsAmout}</p>
          </div>
          <div className="extrato-table__footer">
            <p className="callout n010">Saldo final disponível</p>
            <p className="callout n010">{balance}</p>
          </div>
        </div>
      )
    return (
      <table className="opcoes-table">
        <thead>
          <tr>
            <th>Data</th>
            <th>Lançamento</th>
            <th></th>
            <th className="text-right">Valor</th>
          </tr>
        </thead>
        <tbody>
          {needPreviousBalance && <tr>
            <td className="t310">{new Date(startDate).toLocaleDateString()}</td>
            <td>Saldo anterior</td>
            <td></td>
            <td className="text-right">{previousBalance}</td>
          </tr>}
          {transactions && transactions.map((transaction) => (
            <tr key={transaction.id} className="">
              <td className="t310">{new Date(transaction.data).toLocaleDateString()}</td>
              <td>
                <div className="extrato-description">
                  {transaction.descricao}
                </div>
              </td>
              <td></td>
              <td className="text-right">{transaction.valor.replace('.', ',')}</td>
            </tr>
          ))}
        </tbody>
        <tfoot className="extrato__table-footer">
          <tr>
            <td>Total ganho</td>
            <td></td>
            <td></td>
            <td className="text-right">{prizes}</td>
          </tr>
          <tr>
            <td>Total apostado</td>
            <td></td>
            <td></td>
            <td className="text-right">{betsAmout}</td>
          </tr>
          <tr>
            <td>Saldo final disponível</td>
            <td></td>
            <td></td>
            <td className="text-right">{balance}</td>
          </tr>
        </tfoot>
      </table>
    )
  }

  return (
    <div>
      <div data-ssr="ignore">
        <section className="extrato container">
          <ul className="breadcrumbs" itemScope="" itemType="http://schema.org/BreadcrumbList">
            <li className="breadcrumbs__container" itemProp="itemListElement" itemScope="" itemType="http://schema.org/ListItem">
              <div itemProp="name">
                <Link className="botao__breadcrumbs" title="Início" itemProp="item" to="/">Início</Link>
              </div>
              <meta itemProp="position" content="1" />
            </li>
          </ul>
          <div className="section-title">
            <div className="title-2 n150">
              <h2 className="title-3">
                Extrato de operações
                <span className="section-title__info dt020"></span>
              </h2>
            </div>
          </div>
          {isMobile ?
            <>
              {renderFilter()}
              {wasRequested && renderTable()}
            </>
          :
            <div className="extrato-wrapper">
              {renderFilter()}
              {wasRequested && renderTable()}
            </div>
          }
        </section>
      </div>
    </div>
  )
}
