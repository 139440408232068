import classnames from 'classnames';
import { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import io from 'socket.io-client';

import Cart from '../components/layout/Cart';
import { generateMatches, generateMatch } from '../utils';

export default function Live({ socketUrl, settings, isMobile, user, selectedOdds, betAmount, handleOddClick, onCleanOdds, calculatePrize, onChangeBetAmount, finishBet }) {
  const matchTimes = {
    '1T': '1º Tempo',
    '2T': '2º Tempo',
    'INT': 'Intervalo'
  }
  const [socket, setSocket] = useState(null)
  const [matches, setMatches] = useState([])
  const [matchesById, setMatchesById] = useState({})

  useEffect(() => {
    const _socket = io(socketUrl, { path: '/api/live/socket.io' })
    _socket.on('live_data', (data) => {
      const _matches = generateMatches(data, settings, true)
      const _matchesById = _matches.reduce((obj, x) => {
        obj[x.id_partida] = x
        return obj
      }, {})
      setMatchesById(_matchesById)
      setMatches(_matches)
    }).on('connect_error', (reason) => {
      console.error('connect_error', reason)
    }).on('disconnect', (reason) => {
      console.error('disconnect', reason)
    })

    setSocket(_socket)
    return () => _socket.close()
  }, [socketUrl, settings])

  useEffect(() => {
    if (socket === null) return

    const updateMatch = (data) => {
      if (data.tempo_jogo >= settings.tempo_bloqueio_ao_vivo)
        return

      const match = generateMatch(data, settings, true)
      const _matchesById = Object.assign({}, matchesById, {
        [match.id_partida]: match
      })
      const _matches = [...Object.values(_matchesById)]

      setMatchesById(_matchesById)
      setMatches(_matches)
    }

    const removeMatch = (data) => {
      const _matchesById = Object.assign({}, matchesById)
      delete _matchesById[data.id_partida]
      const _matches = [...Object.values(_matchesById)]

      setMatchesById(_matchesById)
      setMatches(_matches)
    }

    socket.on('live_match', updateMatch)
    socket.on('remove_match', removeMatch)

    return () => {
      socket.off('live_match', updateMatch)
      socket.off('remove_match', removeMatch)
    }
  }, [socket, matches, matchesById, settings])

  if (isMobile) {
    return (
      <>
        <Helmet>
          <title>{settings.nome}</title>
          <meta name="keywords" content={settings.chaves} />
          <meta name="description" content={settings.descricao} />
          <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.11.0/css/all.css" />
        </Helmet>
        <div className="wrap-content" style={{ minHeight: '120vh' }}>
          <div>
            <div className="new-layout">
              <div className="home container">
                {matches.length > 0 ? (
                  <section className="eventwrap">
                    <div className="eventwrap__head showing-sport-icon" style={{ marginTop: '5.6rem' }}>
                      <div className="eventwrap__title__icon"></div>
                      <div className="section-description">
                        <h2>Futebol</h2>
                      </div>
                    </div>
                    <div className="eventwrap__labels--mobile">
                      <div className="eventwrap__labels--mobile_tree">
                        <span></span>
                        <span className="label">Casa</span>
                        <span className="label">Empate</span>
                        <span className="label">Fora</span>
                      </div>
                    </div>
                    <ul className="eventos-list shadow-box-mobile">
                      {matches.map(match => (
                        <li className="eventos-list__item" key={match.id_partida}>
                          <a className="eventos-item" href={`/sports/${/*match.esporte*/ 'soccer'}/${match.campeonato}/${match.id_partida}/live`}>
                            <div className="momento-info">
                              <div className="eventos-item__date caption-2 dt010">
                                <div className="eventos-item__aovivo">AO VIVO</div>
                                {matchTimes[match.tempo]}
                                <br />
                                <span>{match.tempo_jogo}</span>
                              </div>
                            </div>
                            <p className="eventos-item__title subhead-demi truncate" title={`${match.mandante} x ${match.visitante}`}>
                              {match.mandante}
                              <br />
                              {match.visitante}
                            </p>
                          </a>
                          <div className="eventos-list__item__odds --has-placar">
                            <div className="eventos-list__item__placar">
                              <div>{match.placar_mandante}</div>
                              <div>{match.placar_visitante}</div>
                            </div>
                            <div className="eventos-list__item__odds-wrap">
                              {['homeOdd', 'drawOdd', 'awayOdd'].map(oddType => {
                                const odd = match[oddType]
                                const isDisabled = +odd.cotacao <= 1.0

                                return (
                                  <div className="sub-event" key={odd.id_tipo}>
                                    <button
                                      className={classnames('button-odd', { 'button-odd--disabled': isDisabled, 'button-odd--active': selectedOdds.find(item => item.match.id_partida === match.id_partida && item.odd.id_tipo === odd.id_tipo) })}
                                      title={`${odd.tipo} ${odd.cotacao}`}
                                      onClick={() => handleOddClick(match, odd, true)}
                                      disabled={isDisabled}
                                    >
                                      <span className={classnames('button-odd-default', { 'cotacao-zero': isDisabled })}>
                                        <span className="button-odd-default__info subhead dt010">
                                          {isDisabled ? <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"></path>
                                          </svg> : odd.cotacao}
                                        </span>
                                      </span>
                                    </button>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </li>
                      ))}
                      <li className="eventos-list__footer">
                        <a href="/aovivo?esporte=futebol">
                          <span>Todos os eventos:</span>
                          <span>{matches.length}
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 320 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                              <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                            </svg>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </section>
                ) : null}
                <span className="" style={{ display: 'inline-block' }}></span>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>{settings.nome}</title>
        <meta name="keywords" content={settings.chaves} />
        <meta name="description" content={settings.descricao} />
        <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.11.0/css/all.css" />
      </Helmet>
      <div>
        <div className="new-layout">
          <div className="menu-left__wrap">
            <div className="menu-left">
              <div className="menu-left__item menu-left__item--highlight">
                <a className="menu-left__item__action" href="/como-funciona">
                  <span className="menu-left__item__action__title">Como funciona</span>
                </a>
              </div>
              {matches.length > 0 && matches.filter(m => +m.favorito).length > 0 ? (
                <ul className="menu-left__group">
                  <li className="menu-left__item menu-left__item--grouphead">
                    <div className="menu-left__item__action">
                      <span className="menu-left__item__action__title">Especiais</span>
                    </div>
                  </li>
                  {matches.map(match => {
                    if (match.favorito && +match.favorito)
                      return (
                        <li className="menu-left__item menu-left__item--groupitem">
                          <a className="links-destaques--item" href={`/sports/${/* match.esporte */ 'soccer'}/${match.campeonato}`}>
                            <span>{match.campeonato}</span>
                          </a>
                        </li>
                      )

                    return null
                  })}
                </ul>
              ) : null}
              <ul className="menu-left__group">
                <li className="menu-left__item menu-left__item--grouphead">
                  <div className="menu-left__item__action">
                    <span className="menu-left__item__action__title">Esportes</span>
                  </div>
                </li>
                <li className="menu-left__item menu-left__item--groupitem  level-1" data-id="4,0">
                  <div className="sport-icon-menu sport-icon-menu__futebol"></div>
                  <a className="menu-left__item__action" href="/sports/soccer">
                    <span className="menu-left__item__action__title">Futebol</span>
                  </a>
                  <div className="menu-left__item__toggle">
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                    </svg>
                  </div>
                </li>
                <li className="menu-left__item menu-left__item--groupitem  level-1" data-id="4,3">
                  <div className="sport-icon-menu sport-icon-menu__basquete"></div>
                  <a className="menu-left__item__action" href="/esportes/basquete">
                    <span className="menu-left__item__action__title">Basquete</span>
                  </a>
                  <div className="menu-left__item__toggle">
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                    </svg>
                  </div>
                </li>
                <li className="menu-left__item menu-left__item--groupitem  level-1" data-id="4,5">
                  <div className="sport-icon-menu sport-icon-menu__boxe"></div>
                  <a className="menu-left__item__action" href="/esportes/boxe">
                    <span className="menu-left__item__action__title">Lutas</span>
                  </a>
                  <div className="menu-left__item__toggle">
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                    </svg>
                  </div>
                </li>
                <li className="menu-left__item menu-left__item--groupitem  level-1" data-id="4,9">
                  <div className="sport-icon-menu sport-icon-menu__hoquei"></div>
                  <a className="menu-left__item__action" href="/esportes/hoquei">
                    <span className="menu-left__item__action__title">Hóquei</span>
                  </a>
                  <div className="menu-left__item__toggle">
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                    </svg>
                  </div>
                </li>
                <li className="menu-left__item menu-left__item--groupitem  level-1" data-id="4,2">
                  <div className="sport-icon-menu sport-icon-menu__voleibol"></div>
                  <a className="menu-left__item__action" href="/esportes/voleibol">
                    <span className="menu-left__item__action__title">Vôleibol</span>
                  </a>
                  <div className="menu-left__item__toggle">
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                    </svg>
                  </div>
                </li>
                <li className="menu-left__item menu-left__item--groupitem  level-1" data-id="4,4">
                  <div className="sport-icon-menu sport-icon-menu__tenis"></div>
                  <a className="menu-left__item__action" href="/esportes/tenis">
                    <span className="menu-left__item__action__title">Tênis</span>
                  </a>
                  <div className="menu-left__item__toggle">
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                    </svg>
                  </div>
                </li>
                <li className="menu-left__item menu-left__item--groupitem  level-1" data-id="4,12">
                  <div className="sport-icon-menu sport-icon-menu__league-of-legends"></div>
                  <a className="menu-left__item__action" href="/esportes/league-of-legends">
                    <span className="menu-left__item__action__title">E-Sports</span>
                  </a>
                  <div className="menu-left__item__toggle">
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                    </svg>
                  </div>
                </li>
              </ul>
              <ul className="menu-left__group">
                <li className="menu-left__item menu-left__item--grouphead">
                  <div className="menu-left__item__action">
                    <span className="menu-left__item__action__title">Institucionais</span>
                  </div>
                </li>
                <li className="menu-left__item menu-left__item--groupitem"><div className="sport-icon-menu sport-icon-menu__quem_somos"></div><a className="menu-left__item__action" href="/quem-somos"><span className="menu-left__item__action__title">Quem somos</span></a></li>
                <li className="menu-left__item menu-left__item--groupitem"><div className="sport-icon-menu sport-icon-menu__ajuda"></div><a className="menu-left__item__action" href="/ajuda"><span className="menu-left__item__action__title">Ajuda</span></a></li>
                <li className="menu-left__item menu-left__item--groupitem"><div className="sport-icon-menu sport-icon-menu__regras"></div><a className="menu-left__item__action" href="/regras"><span className="menu-left__item__action__title">Regras</span></a></li>
              </ul>
            </div>
          </div>
          <div className="home container">
            {matches.length > 0 ? (
              <section className="eventwrap">
                <div className="eventwrap__head">
                  <div className="section-description">
                    <h2>Futebol</h2>
                  </div>
                  <div className="eventwrap__labels">
                    <span className="label">Casa</span>
                    <span className="label">Empate</span>
                    <span className="label">Fora</span>
                  </div>
                </div>
                <ul className="eventos-list shadow-box-mobile">
                  {matches.map(match => (
                    <li className="eventos-list__item" key={match.id_partida}>
                      <a className="eventos-item" href={`/sports/${/*match.esporte*/ 'soccer'}/${match.campeonato}/${match.id_partida}/live`}>
                        <div className="momento-info">
                          <div className="eventos-item__date caption-2 dt010">
                            <div className="eventos-item__aovivo">AO VIVO</div>
                            {matchTimes[match.tempo]}
                            <br />
                            <span>{match.tempo_jogo}</span>
                          </div>
                        </div>
                        <p className="eventos-item__title subhead-demi truncate" title={`${match.mandante} x ${match.visitante}`}>
                          {match.mandante}
                          <br />
                          {match.visitante}
                        </p>
                      </a>
                      <div className="eventos-list__item__odds --has-placar">
                        <div className="eventos-list__item__placar">
                          <div>{match.placar_mandante}</div>
                          <div>{match.placar_visitante}</div>
                        </div>
                        <div className="eventos-list__item__odds-wrap">
                          {['homeOdd', 'drawOdd', 'awayOdd'].map(oddType => {
                            const odd = match[oddType]
                            const isDisabled = +odd.cotacao <= 1.0

                            return (
                              <div className="sub-event" key={odd.id_tipo}>
                                <button
                                  className={classnames('button-odd', { 'button-odd--disabled': isDisabled, 'button-odd--active': selectedOdds.find(item => item.match.id_partida === match.id_partida && item.odd.id_tipo === odd.id_tipo && (!!odd.jogador_id ? item.odd.jogador_id === odd.jogador_id : true)) })}
                                  title={`${odd.tipo} ${odd.cotacao}`}
                                  onClick={() => handleOddClick(match, odd, true)}
                                  disabled={isDisabled}
                                >
                                  <span className={classnames('button-odd-default', { 'cotacao-zero': isDisabled })}>
                                    <span className="button-odd-default__info subhead dt010">
                                      {isDisabled ? <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"></path>
                                      </svg> : odd.cotacao}
                                    </span>
                                  </span>
                                </button>
                              </div>
                            )
                          })}
                        </div>
                        <a className="button-odd button-odd--plus eventos-list__item__odds__plus button-link" href={`/sports/soccer/${match.campeonato}/${match.id_partida}/live`} title="Ver mais odds">
                          <span className="button-odd-plus__title subhead dt030">+</span>
                        </a>
                      </div>
                    </li>
                  ))}
                </ul>
              </section>
            ) : null}
          </div>
          <Cart
            settings={settings}
            user={user}
            betAmount={betAmount}
            onChangeBetAmount={onChangeBetAmount}
            calculatePrize={calculatePrize}
            finishBet={finishBet}
            odds={selectedOdds}
            onRemoveOdd={handleOddClick}
            onCleanOdds={onCleanOdds}
          />
        </div>
      </div>
    </>
  )
}
