import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Select from 'react-select'

export default function Withdraws({ apiUrl, settings, isMobile, user, loadUser }) {
  const hasBank = user && user.conta_bancaria
  const userBank = hasBank ? user.conta_bancaria : null
  const [withdraw, setWithdraw] = useState('0,00')
  const [bank, setBank] = useState(null)
  const [agency, setAgency] = useState('')
  const [account, setAccount] = useState('')
  const [accountType, setAccountType] = useState('')
  const [error, setError] = useState(null)
  const bankOptions = settings.bancos ? settings.bancos.map((b) => ({ value: b.id, label: b.nome })) : []
  const accountOptions = settings.contas_tipos ? settings.contas_tipos.map((t) => ({ value: t.id, label: t.nome })) : []

  useEffect(() => {
    if (hasBank) {
      setBank({ value: userBank.banco_id, label: userBank.banco })
      setAgency(userBank.agencia)
      setAccount(userBank.conta)
      setAccountType({ value: userBank.tipo_id, label: userBank.conta_tipo })
    }
  }, [userBank, hasBank])

  const handleSubmit = (event) => {
    event.preventDefault()

    const value = parseFloat(withdraw.replace(',', '.'))
    const token = localStorage.getItem('token')

    if (value === 0) {
      setError({title: 'Atenção', 'description': 'Faça um saque com um valor válido'})
      return
    }

    if (!hasBank || (hasBank && (bank.value !== userBank.banco_id || agency !== userBank.agencia || account !== userBank.conta))) {
      fetch(`${apiUrl}/api/conta-banco`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": `Bearer ${token}`
        },
        body: new URLSearchParams({
          banco: bank.value,
          agencia: agency,
          conta: account,
          tipo_conta: 0,
          titular: '',
          titular_cpf: user.cpf
        })
      })
      .then((res) => res.json())
      .then((data) => {
        if (data.resposta) {
          loadUser()
        } else {
          setError({title: data.mensagem, description: data.descricao})
          return
        }
      })
    }

    if (value > user.saldo) {
      setError({description: 'Você não tem saldo suficiente para realizar o saque!'})
      return
    }

    if (value < 1.0) {
      setError({description: 'O valor mínimo para saques é de R$ 1,00'})
      return
    }

    fetch(`${apiUrl}/api/saque`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${token}`
      },
      body: new URLSearchParams({
        valor: value
      })
    })
    .then((res) => res.json())
    .then((data) => {
      if (data.resposta) {
        // loadWithdraws
        loadUser()
        setWithdraw("0,00")
      } else {
        setError({title: data.mensagem, description: data.descricao})
      }
    })
  }

  const renderFilter = () => (
    <div className={`saque__filter ${!isMobile ? 'shadow-on-hover' : ''}`}>
      <div className="saque__detalhes">
        <p className="callout n150">Detalhes do saldo</p>
        <div className="saque__detalhes__info">
          <p className="footnote dt020">Saldo total</p>
          <p className="footnote n150">{user && user.saldo}</p>
        </div>
        <p className="callout n150">Solicitar saque</p>
      </div>
      <label className="input-label select">
        <Select
          options={bankOptions}
          value={bank}
          onChange={(option) => setBank(option)}
          classNamePrefix="input-select"
          placeholder="Selecione"
        />
        <p className="input-label__label caption-3 dt020">Tipo de Saque </p>
      </label>
      <form className="form-pin" onSubmit={handleSubmit}>
        <div className="saque__warning-box-container">
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="19">
            <path d="M1 21h22L12 2 1 21zm12-4h-2v-3h2v3zm0-4h-2v-4h2v4z" style={{fill: 'var(--y030)'}}></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
          <span>O Prazo máximo de compensação do saque é de (72 horas úteis).</span>
        </div>
        <label className="input-label">
          <input name="cpf" label="CPF" type="text" placeholder="123.456.789-10" className="input input--disabled" disabled="" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" value={user && user.cpf} />
          <p className="input-label__label caption-3 dt030">CPF </p>
        </label>
        <label className="input-label">
          <input className="input  input--disabled" disabled="" name="beneficiaryName" label="Nome" type="text" placeholder="" value={user && user.nome} />
          <p className="input-label__label caption-3 dt030">Nome </p>
        </label>
        <label className="input-label">
          <input className="input " name="bankBranch" label="Agência *" type="text" placeholder="" value={agency} />
          <p className="input-label__label caption-3 dt020">Agência * </p>
        </label>
        <label className="input-label">
          <input className="input " name="bankAccount" label="Conta (com dígito)" type="text" placeholder="" value={account} />
          <p className="input-label__label caption-3 dt020">Conta (com dígito) </p>
        </label>
        <label className="input-label select">
          <Select
            options={accountOptions}
            value={accountType}
            onChange={(option) => setAccountType(option)}
            classNamePrefix="input-select"
            placeholder="Selecione"
          />
          <p className="input-label__label caption-3 dt020">Conta Poupança </p>
        </label>
        <label className="input-label">
          <input className="input " value={withdraw} onChange={({target}) => setWithdraw(target.value)} />
          <p className="input-label__label caption-3 dt020">Valor </p>
        </label>
        {error && <p className="footnote r010 text-center">{error.description}</p>}
        <button className={`button-flat button-flat-6${!agency || !account || !accountType || (!withdraw || parseFloat(withdraw.replace(',', '.')) < 1) ? ' button-flat-6--disabled' : ''}`} disabled={!agency || !account || !accountType || (!withdraw || parseFloat(withdraw.replace(',', '.')) < 1)}>
          <span className="button-flat-6__title caption-2 lt010">Solicitar SAQUE</span>
        </button>
      </form>
    </div>
  )

  const renderHistory = () => (
    <div style={{display: 'grid', gridGap: '1.6rem 1.6rem'}}>
      <div className="section-title ">
        <div className="title-2 n150">
          <h2 className="title-3"> Histórico<span className="section-title__info dt020"></span></h2>
        </div>
      </div>
      <div className="section-title ">
        <div className="title-2 n150">
          <h2 className="title-3"> Sem Movimento<span className="section-title__info dt020"></span></h2>
        </div>
      </div>
    </div>
  )

  return (
    <div>
      <div data-ssr="ignore">
        <section className="saque container">
          <ul className="breadcrumbs" itemScope="" itemType="http://schema.org/BreadcrumbList">
            <li className="breadcrumbs__container" itemProp="itemListElement" itemScope="" itemType="http://schema.org/ListItem">
              <div itemProp="name">
                <Link className="botao__breadcrumbs" title="Início" itemProp="item" to="/">Início</Link>
              </div>
              <meta itemProp="position" content="1" />
            </li>
          </ul>
          <div className="section-title">
            <div className="title-2 n150">
              <h2 className="title-3">
                Saque<span className="section-title__info dt020"></span>
              </h2>
            </div>
          </div>
          {isMobile ?
            <>
              {renderFilter()}
              {renderHistory()}
            </>
          :
            <>
              <div className="saque-wrapper">
                {renderFilter()}
              </div>
              <div className="saque-wrapper">
                {renderHistory()}
              </div>
            </>
          }
        </section>
      </div>
    </div>
  )
}
