import { useState } from 'react';
import { Link } from "react-router-dom";
import classnames from 'classnames';
import NumberFormat from 'react-number-format';

import { ReactComponent as Minus } from '../assets/icons/minus.svg';
import { ReactComponent as Plus } from '../assets/icons/plus.svg';
import { ReactComponent as Warning } from '../assets/icons/warning.svg'
import Pix from '../assets/img/pix.png'

export default function Deposits({ apiUrl, settings }) {
  const adminBank = (settings && settings.conta_banco) || null
  const token = localStorage.getItem('token')
  const minValue = settings.deposito_minimo || 1
  const valueOptions = [50, 100, 120, 200];
  const [depositValue, setDepositValue] = useState(50.00)
  const [error, setError] = useState(null)
  const [confirm, setConfirm] = useState(false)
  const [file, setFile] = useState(null)
  const [deposit, setDeposit] = useState(0)

  const addValue = (value) => {
    setDepositValue(value + (depositValue || 0))
  }

  const updateStatus = (id, status) => {
    fetch(`${apiUrl}/api/statusDeposito`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${token}`
      },
      credentials: 'include',
      body: new URLSearchParams({
        status,
        deposito: id
      })
    })
    .then((res) => res.json())
    .then((data) => {
      if (!data.resposta) {
        setError({title: data.mensagem, description: data.descricao})
      }
    })
  }

  const submitProof = () => {
    const data = new FormData()
    data.append('id', deposit)
    data.append('file', file)

    fetch(`${apiUrl}/api/comprovante`, {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": `Bearer ${token}`
      },
      body: data
    })
    .then((res) => res.json())
    .then((data) => {
      if (data.resposta) {
        updateStatus(deposit, 2)
        window.location.reload()
      } else {
        setError({title: data.mensagem, description: data.descricao})
      }
    })
  }

  const handleDeposit = () => {
    if (depositValue === 0) {
      setError({title: 'Atenção', 'description': 'Faça um depósito com um valor válido'})
    }

    fetch(`${apiUrl}/api/deposito`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${token}`
      },
      body: new URLSearchParams({
        valor: depositValue
      })
    })
    .then((res) => res.json())
    .then((data) => {
      if (data.resposta) {
        setDeposit(data.deposito.id)
        setConfirm(true)
      } else {
        setError({title: data.mensagem, description: data.descricao})
      }
    })
  }

  if (confirm) {
    return (
      <div>
        <div data-ssr="ignore">
          <section className="deposito container">
            <ul className="breadcrumbs" itemScope="" itemType="http://schema.org/BreadcrumbList">
              <li className="breadcrumbs__container" itemProp="itemListElement" itemScope="" itemType="http://schema.org/ListItem">
                <div itemProp="name">
                  <Link className="botao__breadcrumbs" title="Início" itemProp="item" to="/">Início</Link>
                </div>
                <meta itemProp="position" content="1" />
              </li>
            </ul>
            <section className="deposito__step-one">
              <header className="deposito__header">
                <h2 className="deposito__header__title">Confirmação de Depósito</h2>
              </header>
              {settings && settings.habilitar_pix && <div style={{display: 'flex', justifyContent: 'center'}}>
                <img src={Pix} alt="QR Code Pix"/>
              </div>}
              <div className="banner-extra-info">
                <span>Para finalizar o pagamento,{settings && settings.habilitar_pix ? ' use o QR Code acima ou' : ''} faça uma transferência conforme os dados abaixo</span>
              </div>

              <div className="deposito__transferdata">
                <div className="deposito__transferdata__item">
                  <div className="deposito__transferdata__item__label">Valor para Depósito</div>
                  <div className="deposito__transferdata__item__value">R$ {depositValue}</div>
                </div>

                <div className="deposito__transferdata__item">
                  <div className="deposito__transferdata__item__label">Banco</div>
                  <div className="deposito__transferdata__item__value">
                    {adminBank && adminBank.banco}{' '}
                  </div>
                </div>

                <div className="deposito__transferdata__item">
                  <div className="deposito__transferdata__item__label">Agência</div>
                  <div className="deposito__transferdata__item__value">
                    {adminBank && adminBank.agencia}{' '}
                  </div>
                </div>

                <div className="deposito__transferdata__item">
                  <div className="deposito__transferdata__item__label">{adminBank && adminBank.tipo_conta}</div>
                  <div className="deposito__transferdata__item__value">
                    {adminBank && adminBank.conta}{' '}
                  </div>
                </div>

                <div className="deposito__transferdata__item">
                  <div className="deposito__transferdata__item__label">
                    Beneficiário{' '}
                  </div>
                  <div className="deposito__transferdata__item__value">{adminBank && adminBank.titular}</div>
                </div>

                <div className="deposito__transferdata__item">
                  <div className="deposito__transferdata__item__label">CPF/CNPJ</div>
                  <div className="deposito__transferdata__item__value">{adminBank && adminBank.titular_cpf}</div>
                </div>

                <div className="deposito__transferdata__item">
                  <div className="deposito__transferdata__item__label">Enviar Comprovante</div>
                  <div className="deposito__transferdata__item__value">
                    <input onChange={({target}) => setFile(target.files[0])} type="file" accept=".png,.jpg" />
                  </div>
                </div>
              </div>
              <div className="modal-deposito-rapido__form">
                <button className={classnames(['button-flat button-flat-11', {'button-flat-11--disabled': !file}])} disabled={!file} onClick={() => submitProof()}>
                  <span className="button-flat-11__title caption-2 lt010">Finalizar</span>
                </button>
              </div>
            </section>
          </section>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div data-ssr="ignore">
        <section className="deposito container">
          <ul className="breadcrumbs" itemScope="" itemType="http://schema.org/BreadcrumbList">
            <li className="breadcrumbs__container" itemProp="itemListElement" itemScope="" itemType="http://schema.org/ListItem">
              <div itemProp="name">
                <Link className="botao__breadcrumbs" title="Início" itemProp="item" to="/">Início</Link>
              </div>
              <meta itemProp="position" content="1" />
            </li>
          </ul>
          <section className="deposito__step-one">
            <header className="deposito__header">
              <h2 className="deposito__header__title">Escolha o valor do Depósito</h2>
              <p>Defina o valor do seu despósito a partir de <strong>R$ {minValue}</strong></p>
            </header>
            <div className="deposito__card deposito__card--step-one">
              <div className="deposito__card__content">
                <div className="deposito__input-valor__container">
                  <div className="deposito__input-valor">
                    <div className="deposito__input-valor__wrap">
                      <button className="deposito__input-valor__button-rounded" type="button" onClick={() => addValue(-1)}>
                        <Minus />
                      </button>
                      <label className="input-label">
                        <NumberFormat className="input" value={depositValue} maxLength="10" thousandSeparator={"."} decimalSeparator={","} decimalScale={2} allowNegative={false} allowLeadingZeros={true} onValueChange={({floatValue}) => setDepositValue(floatValue)} />
                        <p className="input-label__label caption-3 dt020">Valor (R$) </p>
                      </label>
                      <button className="deposito__input-valor__button-rounded" type="button" onClick={() => addValue(1)}>
                        <Plus />
                      </button>
                    </div>
                    <ul className="deposito__input-valor__list">
                      {valueOptions.map(v => (
                        <li key={v}>
                          <button className="deposito__input-valor__button-square" type="button" onClick={() => addValue(v)}>+{v}</button>
                        </li>  
                      ))}
                    </ul>
                  </div>
                </div>
                {error && <div className="deposito__warning-container">
                  <Warning />
                  <span>
                    <strong>{error.title}:</strong>
                    <div className="text-danger">{error.description}</div>
                  </span>
                </div>}
              </div>
            </div>
          </section>
          <div className="deposito__wrap_button">
            <button className={classnames(['button-flat button-flat-11', {'button-flat-11--disabled': depositValue < minValue}])} disabled={depositValue < minValue} onClick={() => handleDeposit()}>
              <span className="button-flat-11__title caption-2 lt010">Depositar</span>
            </button>
          </div>
        </section>
      </div>
    </div>
  )
}
