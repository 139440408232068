import { useState } from 'react'
import Modal from 'react-modal'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

export default function LoginModal({ show, onHide, apiUrl, isMobile, loadUser }) {
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [btnTitle, setBtnTitle] = useState('Login')
  const [error, setError] = useState(null)
  const [disable, setDisable] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleLogin = async (event) => {
    event.preventDefault()
    setBtnTitle('Carregando...')
    setDisable(true)
    const captchaToken = await executeRecaptcha("login_cliente_lite")

    fetch(`${apiUrl}/api/login`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: new URLSearchParams({
        login,
        senha: password,
        'g-recaptcha-response': captchaToken
      })
    })
    .then((res) => res.json())
    .then((data) => {
      if (data.resposta) {
        localStorage.setItem('token', data.token)
        loadUser()
        onHide()
      } else {
        setError(data.descricao)
        setBtnTitle('Login')
        setDisable(false)
      }
    })
  }

  Modal.setAppElement(document.body)

  const renderContent = () => {
    if (isMobile) {
      return (
        <>
          <div className="modal-login">
            <form className="modal-login__form" onSubmit={handleLogin}>
              <label className="input-label">
                <input className="input" name="usuario" label="Email" autocapitalize="off" type="text" placeholder="" value={login} onChange={({target}) => setLogin(target.value)} />
                <p className="input-label__label caption-3 dt020">Email </p>
              </label>
              <label className="input-label">
                <input className="input " type="password" name="senha" label="Senha" placeholder="" value={password} onChange={({target}) => setPassword(target.value)} />
                <p className="input-label__label caption-3 dt020">Senha </p>
              </label>
              <button className={`button-flat button-flat-6${!login || !password || disable ? ' button-flat-6--disabled' : ''}`} disabled={!login || !password || disable}>
                <span className="button-flat-6__title caption-2 lt010">{btnTitle}</span>
              </button>
              <a className="footnote dt020 margin-top-8" href="/">Esqueceu a senha?</a>
            </form>
          </div>
          {error && <div className="display-message error">
            {error}
            <button onClick={() => setError(null)}>
              <span>OK</span>
            </button>
          </div>}
          <div className="modal__footer">
            <div>Não possui conta?</div>
            <button className="button-flat button-flat-6">
              <span className="button-flat-6__title caption-2 lt010">Cadastrar</span>
            </button>
          </div>
        </>
      )
    }

    return (
      <div className="modal-login">
        <form className="modal-login__form" onSubmit={handleLogin}>
          <label className="input-label">
            <input className="input" name="usuario" label="Email" type="text" placeholder="Usuário" value={login} onChange={({target}) => setLogin(target.value)} />
            <p className="input-label__label caption-3 dt020">Email</p>
          </label>
          <label className="input-label">
            <input className="input" type="password" name="senha" label="Senha" placeholder="Senha" value={password} onChange={({target}) => setPassword(target.value)} />
            <p className="input-label__label caption-3 dt020">Senha</p>
          </label>
          <button className={`button-flat button-flat-6${!login || !password || disable ? ' button-flat-6--disabled' : ''}`} disabled={!login || !password || disable}>
            <span className="button-flat-6__title caption-2 lt010">{btnTitle}</span>
          </button>
          {error && <p className="footnote r010 text-center">{error}</p>}
          <a className="footnote dt020 margin-top-8" href="/">Esqueceu a senha?</a>
        </form>
        <div className="modal-login__banner">
          <div className="modal-login__banner__cadastro">
            <p className="title-3 n010">Não possui conta?</p>
            <p className="footnote lt010 margin-8-top">Cadastre-se agora! É rápido e fácil</p>
            <button className="button-flat button-flat-5 btn_dark">
              <span className="button-flat-5__title caption-2 tt110">Cadastrar</span>
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Modal
      isOpen={show}
      className="modal"
      overlayClassName="modal-overlay modal-overlay--floating"
      bodyOpenClassName="body--modal-open"
    >
      <header className="modal-header">
        <div className="modal-header__left">
          <h1 className="title-2 n150">Login</h1>
        </div>
        <button className="display-grid" onClick={() => onHide()}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" style={{fill: 'var(--t310)'}}></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </button>
      </header>
      {renderContent()}
    </Modal>
  )
}
