import { Link } from "react-router-dom";

import { ReactComponent as ArrowUp } from '../../assets/icons/arrow-up.svg'
import { ReactComponent as IdadeLegal } from '../../assets/icons/idade-legal.svg'
import { ReactComponent as Facebook } from '../../assets/icons/social/facebook.svg'
import { ReactComponent as Instagram } from '../../assets/icons/social/instagram.svg'
import { ReactComponent as Twitter } from '../../assets/icons/social/twitter.svg'

export default function Footer({ settings }) {
  const scrollToTop = (e) => {
    e.preventDefault()
    window.scrollTo(0,0)
  }

  return (
    <div className="wrap-footer">
      <footer className="footer">
        <div className="footer-social" data-ssr="ignore">
          <div className="footer-container container social">
            <div className="footer-container__grid social">
              <div className="footer-nav">
                <div className="footer-nav__title callout n150">Informações</div>
                <div className="footer-informativo-payment">
                  <Link className="button-flat button-flat-1 button-link" to="/como-funciona">
                    <span className="button-flat-1__title footnote dt010">Como funciona</span>
                  </Link>
                  <Link className="button-flat button-flat-1 button-link" to="/termos">
                    <span className="button-flat-1__title footnote dt010">Termos e Condições</span>
                  </Link>
                  <Link className="button-flat button-flat-1 button-link" to="/ajuda">
                    <span className="button-flat-1__title footnote dt010">Ajuda</span>
                  </Link>
                  <Link className="button-flat button-flat-1 button-link" to="/regras">
                    <span className="button-flat-1__title footnote dt010">Regras e Procedimentos</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-container__grid social icons">
            <div className="social-links">
              {settings.facebook && <a href={`https://facebook.com/${settings.facebook}`} target="_blank" rel="noopener noreferrer" title="Facebook">
                <Facebook />
              </a>}
              {settings.instagram && <a href={`https://instagram.com/${settings.instagram}`} target="_blank" rel="noopener noreferrer" title="Instagram">
                <Instagram />
              </a>}
              {settings.twitter && <a href={`https://twitter.com/${settings.twitter}`} target="_blank" rel="noopener noreferrer" title="Twitter">
                <Twitter />
              </a>}
            </div>
            <div className="footer-container__sponsor">
              <div className="footer-container__sponsor__title">Patrocinador Oficial</div>
              <div className="footer-container__sponsor__logos">
                <div className="footer-container__sponsor__logos__imgs">
                  <img alt="São Paulo Futebol Clube" className="footer-container__sponsor__logo footer-container__sponsor__logo--spfc" src="/static/img/sponsor/spfc.svg" />
                </div>
              </div>
            </div>
          </div>
          <div className="footer-payment-legal">
            <div className="container-messages">
              <div className="footer-payment-legal-container__grid">
                <div className="footer-payment-idadelegal">
                  <IdadeLegal />
                  <span className="footnote dt010 footer-payment-idadelegal-text">Jogo é proibido a menores de 18 anos, oferece risco de grandes perdas financeiras e em excesso podem causar riscos à saúde. Veja nossa página de Jogo Responsável para mais detalhes e as ferramentas disponíveis. Jogue com responsabilidade: www.gamblersanonymous.org</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bar">
          <div className="footer-bar__wrapper full-container">
            <p className="callout n010">inobets.com</p>
            <a className="footer-bar__to-top" href="/" onClick={scrollToTop} title="Ir para o Topo">
              <ArrowUp />
            </a>
          </div>
        </div>
      </footer>
    </div>
  )
}
