import { useCallback, useState, useEffect, useLayoutEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { Switch, Route } from "react-router-dom";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import Index from './pages/Index';
import Deposits from './pages/Deposits';
import Withdraws from './pages/Withdraws';
import Extract from './pages/Extract';
import Soccer from './pages/Soccer';
import Championships from './pages/Championships';
import Match from './pages/Match';
import Live from './pages/Live';

import Header from './components/layout/Header'
import Footer from './components/layout/Footer'
import FloatMenu from './components/layout/FloatMenu'

export default function App({ root }) {
  const apiUrl = process.env.REACT_APP_API_URL
  const socketUrl = process.env.REACT_APP_SOCKET_URL

  const [mobile, setMobile] = useState(false);
  const [user, setUser] = useState(null)
  const [settings, setSettings] = useState({})
  const [selectedOdds, setSelectedOdds] = useState([])
  const [showLogin, setShowLogin] = useState(false)
  const [showSignup, setShowSignup] = useState(false)
  const [betAmount, setBetAmount] = useState(0);
  const { executeRecaptcha } = useGoogleReCaptcha()

  useLayoutEffect(() => {
    function updateSize() {
      setMobile(document.documentElement.clientWidth <= 768)
    }
    updateSize()
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  if (mobile) {
    root.classList.remove("desktop")
    document.documentElement.classList.remove("desktop")
    root.classList.add("mobile")
    document.documentElement.classList.add("mobile")
  } else {
    root.classList.remove("mobile")
    document.documentElement.classList.remove("mobile")
    root.classList.add("desktop")
    document.documentElement.classList.add("desktop")
  }

  const loadSettings = useCallback(() => {
    fetch(`${apiUrl}/api/config`)
    .then((res) => res.json())
    .then((data) => setSettings(data))
  }, [apiUrl])

  const loadUser = useCallback(() => {
    const token = localStorage.getItem('token')

    if (token === null) {
      setUser(null)
      return
    }

    fetch(`${apiUrl}/api/usuario`, {
      headers: {
        "Authorization": `Bearer ${token}`
      },
      credentials: 'include'
    })
    .then((res) => res.json())
    .then((data) => {
      setUser(data)
    })
  }, [apiUrl])

  useEffect(() => {
    loadSettings()
    loadUser()
    setMobile(isMobile)
  }, [loadSettings, loadUser])

  const hideSignupModal = () => {
    setShowSignup(false)
    setShowLogin(true)
  }

  const handleOddClick = (match, odd, live = false) => {
    const sameMatchOdd = selectedOdds.find((o) => o.match.id_partida === match.id_partida)
    const _selectedOdds = [...selectedOdds]

    if (sameMatchOdd)
      _selectedOdds.splice(_selectedOdds.indexOf(sameMatchOdd), 1)

    if (!sameMatchOdd || sameMatchOdd.odd.id_tipo !== odd.id_tipo)
      _selectedOdds.push({ match, odd, live })

    setSelectedOdds(_selectedOdds)
  }

  const onCleanOdds = () => {
    setSelectedOdds([])
  }

  const calculatePrize = () => {
    return betAmount * selectedOdds.reduce((acc, odd) => acc * +odd.odd.cotacao, 1)
  }

  const onChangeBetAmount = (val, plus = false) => {
    if (!val) {
      setBetAmount(0)
      return
    }

    let amount = 0;

    if (settings.habilitar_centavos) {
      val = '' + val

      if (+betAmount === 0 && !!val)
        val = val.replace('0', '')

      amount = val.replace('.', ',');
    } else {
      if (plus)
        amount = betAmount + val;
      else
        amount = +val;
    }

    if (!isNaN(amount) && amount > settings.max_bet_amount) {
      setBetAmount(settings.max_bet_amount)
      return
    }

    if (('' + amount).search(/(?=,*?\d)^(\d+)?(,)?(\d{1,2})?$/g) >= 0)
      setBetAmount(amount)
  }

  const handleValidation = (pin, senha = '') => {
    const params = { opcao: 1, cod: pin, senha }
    const body = Object.keys(params).reduce((data, key) => {
      data.append(key, params[key] ? params[key] : '')
      return data;
    }, new FormData());
    const headers = {}
    const token = localStorage.getItem('token')

    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }

    fetch(`${apiUrl}/painel/api/statusAposta`, {
      method: 'POST',
      body,
      headers
    })
      .then(response => response.json())
      .then(data => {
        // const cod = data.cod
        // const alertTicketSuccess = () => {
        //   alertify.confirm("Validado com Sucesso!", `Seu bilhete código <strong style="color:green;">${cod}</strong> encontra-se validado.`,
        //     () => shareTicket(cod),
        //     () => showTicket(cod)
        //   ).set({ padding: true, labels: { ok: (settings.compartilhar_comprovante ? 'Compartilhar' : 'Fechar'), cancel: 'Ver Bilhete' } })
        // }

        if (data.resposta) {
          // loadTicket(cod).then(data => {
          //   if (!data.resposta) {
          //     alertify.alert(data.mensagem, data.descricao)
          //   } else if (user.nivel != 3 && canPrintTicket(data)) {
          //     alertify.confirm("Imprimir", `Deseja imprimir o bilhete?`, () => {
          //       if (isApp) {
          //         callNative('printTicket', data)
          //       } else {
          //         desktopPrint(generatePrintContent(data.BILHETE, data.ITENS, settings))
          //       }
          //       setTimeout(alertTicketSuccess, 500)
          //     }, () => setTimeout(alertTicketSuccess, 500)).set({
          //       padding: true, labels: { ok: 'Sim', cancel: 'Não' }
          //     })
          //   } else {
          //     alertTicketSuccess()
          //   }
          // })
        } else {
          if (data.reconfirmar) {
            // alertify.confirm(data.mensagem, data.descricao, () => validateBet(cod), () => { })
            //   .set({ padding: true, labels: { ok: "Tentar Novamente", cancel: "Cancelar" } })
          } else {
            // alertify.alert(data.mensagem, data.descricao)
          }
        }
      }).catch(console.error)
  }

  const finishBet = async () => {
    const params = {
      CODIGO: null,
      APOSTADOR: user.nome,
      VALOR_APOSTA: betAmount,
      CAPTCHA: await executeRecaptcha('finalizar_aposta_lite')
    }

    selectedOdds.forEach(({ match, odd, live }, i) => {
      params[`APOSTAS[${i}][tipo]`] = odd.id_tipo
      params[`APOSTAS[${i}][aposta]`] = odd.id_aposta
      params[`APOSTAS[${i}][partida]`] = match.id_partida
      params[`APOSTAS[${i}][cota]`] = odd.cotacao
      params[`APOSTAS[${i}][ao_vivo]`] = +live
    })

    const body = Object.keys(params).reduce((data, key) => {
      data.append(key, params[key] ? params[key] : '')
      return data;
    }, new FormData());
    const headers = {}
    const token = localStorage.getItem('token')

    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }

    try {
      const response = await fetch(`${apiUrl}/api/finalizar`, {
        method: 'POST',
        body,
        headers
      });

      const data = await response.json()

      if (data.resposta) {
        if (settings.solicitar_confirmacao) {
          const pass = prompt('Por favor, informe sua senha')
          handleValidation(data.PIN, pass)
        } else {
          handleValidation(data.PIN)
        }
      } else {
        data.apostas && onCleanOdds()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const renderRouter = () => (
    <Switch>
      <Route path="/sports/soccer/:championship/:match_id">
        <Match
          apiUrl={apiUrl}
          socketUrl={socketUrl}
          settings={settings}
          isMobile={mobile}
          user={user}
          betAmount={betAmount}
          onChangeBetAmount={onChangeBetAmount}
          calculatePrize={calculatePrize}
          finishBet={finishBet}
          selectedOdds={selectedOdds}
          handleOddClick={handleOddClick}
          onCleanOdds={onCleanOdds}
        />
      </Route>
      <Route exact path="/sports/soccer/:championship">
        <Championships
          apiUrl={apiUrl}
          settings={settings}
          isMobile={mobile}
          user={user}
          betAmount={betAmount}
          onChangeBetAmount={onChangeBetAmount}
          calculatePrize={calculatePrize}
          finishBet={finishBet}
          selectedOdds={selectedOdds}
          handleOddClick={handleOddClick}
          onCleanOdds={onCleanOdds}
        />
      </Route>
      <Route exact path="/sports/soccer">
        <Soccer
          apiUrl={apiUrl}
          settings={settings}
          isMobile={mobile}
          user={user}
          betAmount={betAmount}
          onChangeBetAmount={onChangeBetAmount}
          calculatePrize={calculatePrize}
          finishBet={finishBet}
          selectedOdds={selectedOdds}
          handleOddClick={handleOddClick}
          onCleanOdds={onCleanOdds}
        />
      </Route>
      <Route exact path="/opcoes/deposito">
        <Deposits
          apiUrl={apiUrl}
          settings={settings}
        />
      </Route>
      <Route exact path="/opcoes/saque">
        <Withdraws
          apiUrl={apiUrl}
          settings={settings}
          isMobile={mobile}
          user={user}
          loadUser={loadUser}
        />
      </Route>
      <Route exact path="/opcoes/extrato">
        <Extract
          apiUrl={apiUrl}
          isMobile={mobile}
          user={user}
        />
      </Route>
      <Route exact path="/aovivo">
        <Live
          socketUrl={socketUrl}
          settings={settings}
          isMobile={mobile}
          user={user}
          selectedOdds={selectedOdds}
          handleOddClick={handleOddClick}
          onCleanOdds={onCleanOdds}
          betAmount={betAmount}
          calculatePrize={calculatePrize}
          onChangeBetAmount={onChangeBetAmount}
          finishBet={finishBet}
        />
      </Route>
      <Route exact path="/">
        <Index
          apiUrl={apiUrl}
          settings={settings}
          isMobile={mobile}
          user={user}
          betAmount={betAmount}
          onChangeBetAmount={onChangeBetAmount}
          calculatePrize={calculatePrize}
          finishBet={finishBet}
          selectedOdds={selectedOdds}
          handleOddClick={handleOddClick}
          onCleanOdds={onCleanOdds}
        />
      </Route>
    </Switch>
  )

  if (mobile) {
    return (
      <>
        <FloatMenu
          settings={settings}
          user={user}
          betAmount={betAmount}
          onChangeBetAmount={onChangeBetAmount}
          calculatePrize={calculatePrize}
          finishBet={finishBet}
          selectedOdds={selectedOdds}
          onRemoveOdd={handleOddClick}
          onCleanOdds={onCleanOdds}
          showLogin={() => setShowLogin(true)}
          showSignup={() => setShowSignup(true)}
        />
        <div className="wrap-content" style={{ minHeight: '120vh' }}>
          <Header
            apiUrl={apiUrl}
            isMobile={mobile}
            user={user}
            loadUser={loadUser}
            settings={settings}
            showLogin={showLogin}
            setShowLogin={setShowLogin}
            showSignup={showSignup}
            setShowSignup={setShowSignup}
            hideSignupModal={() => hideSignupModal()}
            selectedOdds={selectedOdds}
          />
          {renderRouter()}
        </div>
        <Footer settings={settings} />
      </>
    )
  }

  return (
    <>
      <div className="wrap-content">
        <Header
          apiUrl={apiUrl}
          isMobile={mobile}
          user={user}
          loadUser={loadUser}
          settings={settings}
          showLogin={showLogin}
          setShowLogin={setShowLogin}
          showSignup={showSignup}
          setShowSignup={setShowSignup}
          hideSignupModal={() => hideSignupModal()}
        />
        {renderRouter()}
      </div>
      <Footer settings={settings} />
    </>
  );
}
