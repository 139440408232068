import classnames from 'classnames';

export default function Cart({ settings, user, betAmount, onChangeBetAmount, calculatePrize, finishBet, odds, onRemoveOdd, onCleanOdds }) {
  return (
    <div className="aside-bar">
      <div className="aside-bar__content">
        <div className="header-carrinho-minhasapostas">
          <div className="header-carrinho-minhasapostas--item active">
            <div className="carrinho">
              <span>Carrinho</span>
              {odds.length > 0 && <span className="option-counter">{odds.length}</span>}
            </div>
          </div>
        </div>
        <div className="wrap-cart">
          <div className="cart">
            <div className="header">
              <span className={odds.length <= 1 ? 'selected' : ''}>Simples</span>
              <span className={odds.length > 1 ? 'selected' : ''}>Combinada</span>
            </div>
            <div className="itens">
              {odds.map(odd => (
                <div className="item" key={`${odd.match.id_partida}-${odd.odd.id_tipo}`}>
                  <div className="left">
                    <div className="team">{odd.odd.tipo}</div>
                    <div className="winner">
                      <span>Tipo de aposta:</span>
                      <span>{odd.odd.categoria}</span>
                    </div>
                    <div className="description">
                      {odd.match.mandante}
                      <br />
                      {odd.match.visitante}
                    </div>
                  </div>
                  <div className="right">
                    <div className="odds">{odd.odd.cotacao}</div>
                    <div className="delete" onClick={() => onRemoveOdd(odd.match, odd.odd, odd.live)}>X</div>
                    {odds.length === 1 && <>
                      <div className="qty">
                        <input value={betAmount} onChange={({ target }) => onChangeBetAmount(target.value)} />
                      </div>
                      <div className="description">
                        Prêmio: 
                        <span>{calculatePrize().toFixed(2).replace('.', ',')}</span>
                      </div>
                    </>}
                  </div>
                </div>
              ))}
            </div>
            <div className="cart__footer">
              <div className="values">
                <span className="input-wrap">
                  <span className="title">Valor da aposta <span>({odds.length} {'aposta' + (odds.length > 1 ? 's' : '')})</span></span>
                  <input value={betAmount} onChange={({ target }) => onChangeBetAmount(target.value)} />
                </span>
                <div className="odds">
                  {[10, 20, 50, 100].map(sum => (
                    <span key={sum} className="odd" onClick={() => onChangeBetAmount(sum, true)}>+{sum}</span>
                  ))}
                </div>
              </div>
              <div className="summary">
                <div>
                  Valor total 
                  <span>{settings.habilitar_centavos ? betAmount : betAmount.toFixed(2).replace('.', ',')}</span>
                </div>
                <div>
                  Provável Prêmio 
                  <span>{calculatePrize().toFixed(2).replace('.', ',')}</span>
                </div>
              </div>
              <div className="actions">
                <button className="button-flat button-flat-10" onClick={() => onCleanOdds()}>
                  <span className="button-flat-10__title caption-2 lt010">Limpar</span>
                </button>
                <button className={classnames('button-flat button-flat-8', { 'button-flat-8--disabled': betAmount === 0 || user === null })} onClick={() => finishBet()} disabled={betAmount === 0 || user === null}>
                  <span className="button-flat-8__title caption-2 lt010">Efetuar aposta</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
