import { useState } from 'react'
import Modal from 'react-modal'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { mask, unMask } from 'node-masker'

export default function SignupModal({ show, onSubmit, onHide, apiUrl, isMobile }) {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [phone, setPhone] = useState('')
  const [cpf, setCpf] = useState('')
  const [adult, setAdult] = useState(false)

  const { executeRecaptcha } = useGoogleReCaptcha()

  const [btnTitle, setBtnTitle] = useState('Cadastrar')
  let disable = false
  const [error, setError] = useState(null)

  const handleSignup = async (event) => {
    event.preventDefault()
    setBtnTitle('Carregando...')
    disable = true
    const captchaToken = await executeRecaptcha("create_user_lite")

    fetch(`${apiUrl}/api/cadastro`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: new URLSearchParams({
        name,
        email,
        password,
        telefone: phone,
        cpf,
        'g-recaptcha-response': captchaToken
      })
    })
    .then((res) => res.json())
    .then((data) => {
      if (data.resposta) {
        onSubmit()
      } else {
        setError(data.descricao)
        setBtnTitle('Cadastrar')
        disable = false
      }
    })
  }

  Modal.setAppElement(document.body)

  return (
    <Modal
      isOpen={show}
      className="modal"
      overlayClassName="modal-overlay modal-overlay--floating"
      bodyOpenClassName="body--modal-open"
    >
      <header className="modal-header">
        <div className="modal-header__left">
          <h1 className="title-2 n150">Cadastrar</h1>
        </div>
        <button className="display-grid" onClick={() => onHide()}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" style={{fill: 'var(--t310)'}}></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </button>
      </header>
      <div className="modal-cadastro-pin">
        <div>
          <div>
            <form className="template" onSubmit={handleSignup}>
              <div style={{gridColumn: 'span 12 / auto'}}>
                <label className="input-label">
                  <input className="input" type="text" name="nome" label="Nome" maxLength="30" placeholder="Nome de usuário" value={name} onChange={({target}) => setName(target.value)} />
                  <p className="input-label__label caption-3 dt020">Nome </p>
                </label>
              </div>
              <div className="confirmar-validacao" style={{gridColumn: 'span 12 / auto'}}>
                <div style={{gridColumn: '1 / span 12'}}>
                  <label className="input-label">
                    <input className="input " type="email" name="email" label="E-mail" placeholder="Email" value={email} onChange={({target}) => setEmail(target.value)} />
                    <p className="input-label__label caption-3 dt020">E-mail </p>
                  </label>
                </div>
              </div>
              <div style={{gridColumn: 'span 12 / auto'}}>
                <label className="input-label">
                  <input className="input " type="password" name="senha" label="Senha" placeholder="*********" value={password} onChange={({target}) => setPassword(target.value)} />
                  <p className="input-label__label caption-3 dt020">Senha </p>
                </label>
              </div>
              <div style={{gridColumn: 'span 12 / auto'}}>
                <label className="input-label">
                  <input type="text" name="cpf" label="CPF" placeholder="Seu CPF" className="input" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" maxLength="14" value={mask(cpf, '999.999.999-99')} onChange={({target}) => setCpf(unMask(target.value))} />
                  <p className="input-label__label caption-3 dt020">CPF </p>
                </label>
              </div>
              <div style={{gridColumn: 'span 12 / auto'}}>
                <label className="input-label">
                  <input type="tel" name="telefone" label="Telefone celular" placeholder="Seu telefone" className="input " autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" value={mask(phone, '(99) 99999-9999')} onChange={({target}) => setPhone(unMask(target.value))} />
                  <p className="input-label__label caption-3 dt020">Telefone celular </p>
                </label>
              </div>
              <div style={{gridColumn: 'span 12 / auto'}}>
                <label className={`input-label checkbox-label${adult ? ' checked' : '' }`} onClick={() => setAdult(!adult)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                    {adult ? <>
                      <path d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" style={{fill: 'var(--t310)'}}></path>
                    </>
                    :
                    <>
                      <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" style={{fill: 'var(--dt010)'}}></path>
                      <path d="M0 0h24v24H0z" fill="none"></path>
                    </>}
                  </svg>
                  <p className="input-label__label caption-3 dt020">
                    Confirmo que tenho idade igual ou superior a 18 anos e que li e concordo com os  <a target="_blank" href="/termos"> Termos e Condições</a>
                  </p>
                </label>
              </div>
              {error && <div className="display-message error" style={{width: 'calc(100% + 3.2rem)', marginLeft: '-1.6rem'}}>
                {error}
                <button onClick={() => setError(null)}>
                  <span>OK</span>
                </button>
              </div>}
              <button className={`button-flat button-flat-11${!name || !password || !cpf || !adult || disable ? ' button-flat-11--disabled' : ''}`} disabled={!name || !password || !cpf || !adult || disable}>
                <span className="button-flat-11__title caption-2 lt010">{btnTitle}</span>
              </button>
              <p>
                Este site é protegido por reCAPTCHA. <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Política de Privacidade</a> e <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">Termos de Serviço</a> do Google são aplicáveis
              </p>
            </form>
          </div>
          {isMobile && <div className="modal__footer">
            <div>Já possui conta?</div>
            <button className="button-flat button-flat-6">
              <span className="button-flat-6__title caption-2 lt010">Login</span>
            </button>
          </div>}
        </div>
        <div className="modal-cadastro-pin__banner">
          <div className="modal-cadastro-pin__banner__login">
            <button className="button-flat button-flat-5 modal-cadastro-jogo-do-bem__banner__button">
              <span className="button-flat-5__title caption-2 tt110">Saiba mais</span>
            </button>
          </div>
        </div>
        {isMobile && <div className="bottom-action-bar">
          <span className="footnote dt010">Já possui conta?</span>
          <button className="button-flat button-flat-5">
            <span className="button-flat-5__title caption-2 tt110">Login</span>
          </button>
        </div>}
      </div>
    </Modal>
  )
}
