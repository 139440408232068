import { useState } from 'react'
import { Link, useHistory } from "react-router-dom"
import classnames from 'classnames'

import LoginModal from '../modals/Login'
import SignupModal from '../modals/Signup'
import contatoImg from '../../assets/img/atendimento.png'
import { ReactComponent as Calendar } from '../../assets/icons/menu/calendario.svg'
import { ReactComponent as Login } from '../../assets/icons/menu/login.svg'
import { ReactComponent as Signup } from '../../assets/icons/menu/signup.svg'
import { ReactComponent as Refresh } from '../../assets/icons/menu/recarregar.svg'
import { ReactComponent as MarketCart } from '../../assets/icons/menu/carrinho.svg'
import { ReactComponent as Info } from '../../assets/icons/menu/info.svg'
import { ReactComponent as Close } from '../../assets/icons/menu/fechar.svg'
import { ReactComponent as Search } from '../../assets/icons/menu/pesquisar.svg'
import { ReactComponent as Menu } from '../../assets/icons/menu/menu.svg'

export default function Header({ apiUrl, isMobile, user, loadUser, settings, showLogin, setShowLogin, showSignup, setShowSignup, hideSignupModal, selectedOdds }) {
  let history = useHistory()
  const [open, setOpen] = useState(false)
  const [searching, setSearching] = useState(false)
  const [showAccountMenu, setShowAccountMenu] = useState(false)
  // const [date, setDate] = useState(new Date())

  // useLayoutEffect(() => {
  //   const timerId = setInterval(() => setDate(new Date()), 1000)
  //   return () => clearInterval(timerId)
  // })

  const logout = () => {
    const token = localStorage.getItem('token')

    fetch(`${apiUrl}/painel/api/sair`, {
      headers: {
        "Authorization": `Bearer ${token}`
      },
      credentials: 'include'
    })
    .then((res) => res.json())
    .then(() => {
      localStorage.setItem('token', null)
      history.push('/')
      window.location.reload()
    })
  }

  const handleMenuClick = (path) => {
    history.push(path)
  }

  const renderActions = () => {
    if (isMobile) {
      if (user)
        return (
          <>
            <Link className="mobile-nav__actions__button" to="/opcoes/deposito">
              <div className="mobile-nav__actions__button__icon"></div>
              <span className="mobile-nav__actions__button__label">Depositar</span>
            </Link>
            <Link className="mobile-nav___actions__release" to="/opcoes/extrato/retorno">
              <Calendar />
              <div className="mobile-nav___actions__release__label">
                Liberação<br />Antecipada
              </div>
            </Link>
          </>
        )
      return (
        <>
          <div className="mobile-nav__actions__button" onClick={() => setShowLogin(true)}>
            <div className="mobile-nav__actions__button__icon">
              <Login />
            </div>
            <span className="mobile-nav__actions__button__label">Login</span>
          </div>
          <div className="mobile-nav__actions__button" onClick={() => setShowSignup(true)}>
            <div className="mobile-nav__actions__button__icon">
              <Signup />
            </div>
            <span className="mobile-nav__actions__button__label">Cadastrar</span>
          </div>
        </>
      )
    }

    return (
      <div className="menu-bar__actions">
        {user && <>
          <div className="menu-bar__actions__person-wrapper">
            <button className="menu-item menu-item--accent menu-bar__actions__person" onClick={() => setShowAccountMenu(!showAccountMenu)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" style={{fill: 'var(--lt010)'}}></path>
                <path d="M0 0h24v24H0z" fill="none"></path>
              </svg>
            </button>
            <div className="menu-bar__actions__opcoes-wrapper">
              {showAccountMenu && <>
                <div className="opcoes ">
                  <div className="opcoes__header">
                    <span className="title-2 n150">Minha Conta</span>
                    <button className="display-grid" onClick={() => setShowAccountMenu(false)}>
                      <Close />
                    </button>
                  </div>
                  <ul className="opcoes__list">
                    <li className="opcoes__list__item">
                      <p className="callout dt010 truncate">{user.nome}</p>
                      <p className="callout dt030">R$ {user.saldo}</p>
                      <div className="display-grid cursor-pointer" title="Ocultar Saldo">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                          <path d="M0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0z" fill="none"></path>
                          <path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z" style={{fill: 'var(--lt010)'}}></path>
                        </svg>
                      </div>
                      <div title="Atualizar Saldo" className="display-grid cursor-pointer">
                        <Refresh />
                      </div>
                    </li>
                    <li className="opcoes__list__item" onClick={() => history.push("/opcoes/apostas")}>
                      <p className="callout dt010">
                        <span className="opcoes__list__item__icon opcoes__list__item__icon--apostas"></span>
                        <span className="opcoes__list__item__label">Minhas Apostas</span>
                      </p>
                    </li>
                    <li className="opcoes__list__item" onClick={() => handleMenuClick("/opcoes/extrato")}>
                      <p className="callout dt010">
                        <span className="opcoes__list__item__icon opcoes__list__item__icon--extrato"></span>
                        <span className="opcoes__list__item__label">Extrato</span>
                      </p>
                    </li>
                    <li className="opcoes__list__item" onClick={() => handleMenuClick("/opcoes/deposito")}>
                      <p className="callout dt010">
                        <span className="opcoes__list__item__icon opcoes__list__item__icon--deposito"></span>
                        <span className="opcoes__list__item__label">Depósito</span>
                      </p>
                    </li>
                    <li className="opcoes__list__item" onClick={() => handleMenuClick("/opcoes/saque")}>
                      <p className="callout dt010">
                        <span className="opcoes__list__item__icon opcoes__list__item__icon--saque"></span>
                        <span className="opcoes__list__item__label">Saque</span>
                      </p>
                    </li>
                    <li className="opcoes__list__item">
                      <p className="callout dt010">
                        <span className="opcoes__list__item__icon opcoes__list__item__icon--promocoes"></span>
                        <span className="opcoes__list__item__label">Meus Bônus</span>
                      </p>
                    </li>
                    <li className="opcoes__list__item">
                      <p className="callout dt010">
                        <span className="opcoes__list__item__icon opcoes__list__item__icon--cadastro"></span>
                        <span className="opcoes__list__item__label">Meus Dados</span>
                      </p>
                    </li>
                    <li className="opcoes__list__item">
                      <p className="callout dt010">
                        <span className="opcoes__list__item__icon opcoes__list__item__icon--verificar-conta"></span>
                        <span className="opcoes__list__item__label">Verificar Conta</span>
                      </p>
                    </li>
                    <li className="opcoes__list__item">
                      <p className="callout dt010">
                        <span className="opcoes__list__item__icon opcoes__list__item__icon--alterar-senha"></span>
                        <span className="opcoes__list__item__label">Alterar Senha</span>
                      </p>
                    </li>
                    <li className="opcoes__list__item" onClick={() => logout()}>
                      <p className="callout dt010">
                        <span className="opcoes__list__item__icon opcoes__list__item__icon--sair"></span>
                        <span className="opcoes__list__item__label">Sair</span>
                      </p>
                    </li>
                  </ul>
                </div>
              </>}
            </div>
          </div>
        </>}
        <div className="menu-bar__actions__contato-wrapper">
          <button className="menu-item menu-item-- menu-bar__actions__contato" title="Contato">
            <img src={contatoImg} alt="atendimento" width="30px" height="30px" fill="none" style={{ fill: 'var(--lt010)' }} />
          </button>
        </div>
      </div>
    )
  }

  const renderUserActions = () => {
    if (user)
      return (
        <ul className="menu-bar__user-actions">
          <li className="half-margin-right">
            <Link className="menu-bar__user-actions__saldo" to="/opcoes/deposito">
              <div className="menu-bar__user-actions__saldo__value truncate">R$ {user.saldo.toFixed(2)}</div>
              <div className="menu-bar__user-actions__saldo__label">Saldo</div>
            </Link>
          </li>
          <li title="Atualizar Saldo" className="display-grid cursor-pointer double-margin-right">
            <Refresh />
          </li>
          <li className="double-margin-right">
            <Link className="button-flat button-flat-11 button-link" to="/opcoes/deposito">
              <span className="button-flat-11__title caption-2 lt010">Depositar</span>
            </Link>
          </li>
          <li>
            <Link className="menu-bar__user-actions__release" to="/opcoes/extrato/retorno">
              <Calendar />
              <div className="menu-bar__user-actions__release__label">
                Liberação<br />Antecipada
              </div>
            </Link>
          </li>
        </ul>
      )

    return (
      <div className="menu-bar__login-cadastrar">
        <button className="button-flat button-flat-6" onClick={() => setShowLogin(true)}>
          <span className="button-flat-6__title caption-2 lt010">Login</span>
        </button>
        <button className="button-flat button-flat-11 btn_dark" onClick={() => setShowSignup(true)}>
          <span className="button-flat-11__title caption-2 lt010">Cadastrar</span>
        </button>
      </div>
    )
  }

  const renderContent = () => {
    if (isMobile)
      return (
        <header className="header-menu">
          <nav className={classnames('mobile-nav', {'-active': open})}>
            <div className="mobile-nav__shadow"></div>
            <div className="mobile-nav__wrapper">
              <div className="mobile-nav__actions">
                {renderActions()}
              </div>
              <ul className="menu-left__group menu-left__group--esportes">
                <li className="menu-left__item menu-left__item--grouphead">
                  <div className="menu-left__item__action">
                    <span className="menu-left__item__action__title">Esportes</span>
                  </div>
                </li>
                <li className="menu-left__item menu-left__item--groupitem menu-left__item__grid" data-id="4,0">
                  <div className="sport-icon-menu sport-icon-menu__futebol"></div>
                  <a className="menu-left__item__action" href="/sports/soccer">
                    <span className="menu-left__item__action__title">Futebol</span>
                  </a>
                </li>
                <li className="menu-left__item menu-left__item--groupitem menu-left__item__grid" data-id="4,3">
                  <div className="sport-icon-menu sport-icon-menu__basquete"></div>
                  <a className="menu-left__item__action" href="/esportes/basquete">
                    <span className="menu-left__item__action__title">Basquete</span>
                  </a>
                </li>
                <li className="menu-left__item menu-left__item--groupitem menu-left__item__grid" data-id="4,5">
                  <div className="sport-icon-menu sport-icon-menu__boxe"></div>
                  <a className="menu-left__item__action" href="/esportes/lutas">
                    <span className="menu-left__item__action__title">Lutas</span>
                  </a>
                </li>
                <li className="menu-left__item menu-left__item--groupitem menu-left__item__grid" data-id="4,8">
                  <div className="sport-icon-menu sport-icon-menu__hoquei"></div>
                  <a className="menu-left__item__action" href="/esportes/hoquei">
                    <span className="menu-left__item__action__title">Hóquei</span>
                  </a>
                </li>
                <li className="menu-left__item menu-left__item--groupitem menu-left__item__grid" data-id="4,2">
                  <div className="sport-icon-menu sport-icon-menu__voleibol"></div>
                  <a className="menu-left__item__action" href="/esportes/voleibol">
                    <span className="menu-left__item__action__title">Voleibol</span>
                  </a>
                </li>
                <li className="menu-left__item menu-left__item--groupitem menu-left__item__grid" data-id="4,4">
                  <div className="sport-icon-menu sport-icon-menu__tenis"></div>
                  <a className="menu-left__item__action" href="/esportes/tenis">
                    <span className="menu-left__item__action__title">Tênis</span>
                  </a>
                </li>
                <li className="menu-left__item menu-left__item--groupitem menu-left__item__grid" data-id="4,11">
                  <div className="sport-icon-menu sport-icon-menu__league-of-legends"></div>
                  <a className="menu-left__item__action" href="/esportes/league-of-legends">
                    <span className="menu-left__item__action__title">E-Sports</span>
                  </a>
                </li>
              </ul>
              <ul className="menu-left__group">
                <li className="menu-left__item menu-left__item--grouphead">
                  <div className="menu-left__item__action">
                    <span className="menu-left__item__action__title">Especiais</span>
                  </div>
                </li>
                <li className="menu-left__item menu-left__item--groupitem">
                  <div className="sport-icon-menu sport-icon-menu__ao_vivo"></div>
                  <a className="menu-left__item__action" href="/aovivo">
                    <span className="menu-left__item__action__title">Ao Vivo</span>
                  </a>
                </li>
                <li className="menu-left__item menu-left__item--groupitem">
                  <a className="links-destaques--item" href="/esportes/basquete/eua/nba">
                    <span>
                      <img src="/aws/bmIyLWJhZGdlcy9ldWE=.svg" alt="" />
                    </span>
                    <span>NBA</span>
                  </a>
                </li>
                <li className="menu-left__item menu-left__item--groupitem">
                  <a className="links-destaques--item" href="/esportes/futebol/espanha/la-liga">
                    <span>
                      <img src="/aws/bmIyLWJhZGdlcy9lc3Bhbmhh.png" alt="" />
                    </span>
                    <span>La Liga</span>
                  </a>
                </li>
                <li className="menu-left__item menu-left__item--groupitem">
                  <a className="links-destaques--item" href="/esportes/futebol/italia/serie-a">
                    <span>
                      <img src="/aws/bmIyLWJhZGdlcy9pdGFsaWE=.png" alt="" />
                    </span>
                    <span>Série A</span>
                  </a>
                </li>
                <li className="menu-left__item menu-left__item--groupitem">
                  <a className="links-destaques--item" href="/esportes/futebol/internacional/copa-libertadores">
                    <span>
                      <img src="/aws/bmIyLWJhZGdlcy9saWJlcnRhZG9yZXM=.png" alt="" />
                    </span>
                    <span>Libertadores</span>
                  </a>
                </li>
                <li className="menu-left__item menu-left__item--groupitem">
                  <a className="links-destaques--item" href="/esportes/futebol/internacional/copa-sul-americana">
                    <span>
                      <img src="/aws/bmIyLWJhZGdlcy9zdWxhbWVyaWNhbmE=.png" alt="" />
                    </span>
                    <span>Sul-Americana</span>
                  </a>
                </li>
                <li className="menu-left__item menu-left__item--groupitem">
                  <a className="links-destaques--item" href="/esportes/futebol/inglaterra/premier-league">
                    <span>
                      <img src="/aws/bmIyLWJhZGdlcy9pbmdsYXRlcnJh.png" alt="" />
                    </span>
                    <span>Premier League</span>
                  </a>
                </li>
              </ul>
              {user &&
                <ul className="menu-left__group menu-left__group--account" id="menu-left-group-account">
                  <li className="menu-left__item menu-left__item--grouphead">
                    <div className="menu-left__item__action">
                      <span className="menu-left__item__action__title">
                        <div>Minha Conta</div>
                        <div>
                          <div className="truncate">
                            Saldo: <span>{user.saldo} BRL</span>
                          </div>
                          <div title="Atualizar Saldo" className="menu-left__item__button_saldo display-grid cursor-pointer">
                            <Refresh />
                          </div>
                        </div>
                      </span>
                    </div>
                  </li>
                  <li className="menu-left__item menu-left__item--groupitem">
                    <div className="sport-icon-menu account-icon-menu__apostas"></div>
                    <Link className="menu-left__item__action" to="/opcoes/apostas">
                      <span className="menu-left__item__action__title">Minhas Apostas</span>
                    </Link>
                  </li>
                  <li className="menu-left__item menu-left__item--groupitem">
                    <div className="sport-icon-menu account-icon-menu__extrato"></div>
                    <Link className="menu-left__item__action" to="/opcoes/extrato">
                      <span className="menu-left__item__action__title">Extrato</span>
                    </Link>
                  </li>
                  <li className="menu-left__item menu-left__item--groupitem">
                    <div className="sport-icon-menu account-icon-menu__deposito"></div>
                    <Link className="menu-left__item__action" to="/opcoes/deposito">
                      <span className="menu-left__item__action__title">Depósito</span>
                    </Link>
                  </li>
                  <li className="menu-left__item menu-left__item--groupitem">
                    <div className="sport-icon-menu account-icon-menu__saque"></div>
                    <Link className="menu-left__item__action" to="/opcoes/saque">
                      <span className="menu-left__item__action__title">Saque</span>
                    </Link>
                  </li>
                  <li className="menu-left__item menu-left__item--groupitem">
                    <div className="sport-icon-menu account-icon-menu__promocoes"></div>
                    <a className="menu-left__item__action" href="/opcoes/bonus">
                      <span className="menu-left__item__action__title">Meus Bônus</span>
                    </a>
                  </li>
                  <li className="menu-left__item menu-left__item--groupitem">
                    <div className="sport-icon-menu account-icon-menu__verificar-conta"></div>
                    <a className="menu-left__item__action" href="/opcoes/verificar-conta">
                      <span className="menu-left__item__action__title">Verificar Conta</span>
                    </a>
                  </li>
                  <li className="menu-left__item menu-left__item--groupitem">
                    <div className="sport-icon-menu account-icon-menu__cadastro"></div>
                    <Link className="menu-left__item__action" to="/opcoes/cadastro">
                      <span className="menu-left__item__action__title">Meus Dados</span>
                    </Link>
                  </li>
                  <li className="menu-left__item menu-left__item--groupitem" id="account-alterar-senha">
                    <div className="sport-icon-menu account-icon-menu__alterar-senha"></div>
                    <a className="menu-left__item__action" href="/opcoes/alterar-senha">
                      <span className="menu-left__item__action__title">Alterar Senha</span>
                    </a>
                  </li>
                  <li className="menu-left__item menu-left__item--groupitem" onClick={() => logout()}>
                    <div className="sport-icon-menu account-icon-menu__sair"></div>
                    <div className="menu-left__item__action">
                      <span className="menu-left__item__action__title">Sair</span>
                    </div>
                  </li>
                </ul>
              }
              <div>
                <ul className="menu-left__group menu-left__group--institucionais" id="menu-left-group-institucionai">
                  <li className="menu-left__item menu-left__item--grouphead">
                    <div className="menu-left__item__action">
                      <span className="menu-left__item__action__title">Institucionais</span>
                    </div>
                  </li>
                  <li className="menu-left__item menu-left__item--groupitem">
                    <div className="sport-icon-menu sport-icon-menu__quem_somos"></div>
                    <Link className="menu-left__item__action" to="/quem-somos">
                      <span className="menu-left__item__action__title">Quem Somos</span>
                    </Link>
                  </li>
                  <li className="menu-left__item menu-left__item--groupitem">
                    <div className="sport-icon-menu sport-icon-menu__ajuda"></div>
                    <Link className="menu-left__item__action" to="/ajuda">
                      <span className="menu-left__item__action__title">Ajuda</span>
                    </Link>
                  </li>
                  <li className="menu-left__item menu-left__item--groupitem">
                    <div className="sport-icon-menu sport-icon-menu__regras"></div>
                    <Link className="menu-left__item__action" to="/regras">
                      <span className="menu-left__item__action__title">Regras</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="mobile-nav__bullets">
              <ul>
                <li>
                  <div className="mobile-nav_bullet">
                    <div className="mobile-nav_bullet__icon">
                      {selectedOdds.length > 0 && <span className="mobile-nav_bullet__icon__badge">{selectedOdds.length}</span>}
                      <MarketCart />
                    </div>
                    <div className="mobile-nav__bullet__label truncate">Carrinho</div>
                  </div>
                </li>
                <li>
                  <a className="mobile-nav_bullet" href="/como-funciona">
                    <div className="mobile-nav_bullet__icon">
                      <Info />
                    </div>
                    <div className="mobile-nav__bullet__label truncate">Como <br /> Funciona</div>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
          <div className={classnames('menu-bar is-esportes', {'menu-is-open': open})}>
            <div className={classnames('scroll-menu__one menu-bar__wrapper', {'searchbar': searching})}>
              {searching ? <>
                <div className="searchesportes searchesportes--mobile">
                  <label for="searchesportes__input" className="searchesportes__search-icon">
                    <Search />
                  </label>
                  <input className="input " id="searchesportes__input" type="text" placeholder="Buscar jogos" autoFocus={searching}/>
                  <button className="searchesportes__close-icon" onClick={() => setSearching(false)}>
                    <Close />
                  </button>
                </div>
              </>
              :
              <>
                <button className="menu-item menu-item--accent" onClick={() => setOpen(!open)}>
                  {open ? <Close /> : <Menu />}
                </button>
                <div className="menu-bar__logo-wrap">
                  <Link className="menu-logo" to="/">
                    <img className="menu-logo__img" src={settings.logo} alt="Logo Betsul" />
                  </Link>
                </div>
                <div className="menu-bar__actions">
                  <button className="menu-item menu-item--accent" onClick={() => setSearching(true)}>
                    <Search />
                  </button>
                  <button className="menu-item menu-item--accent ">
                    <img src={contatoImg} alt="atendimento" width="30px" height="30px" fill="none" style={{ fill: 'var(--lt010)' }} />
                  </button>
                </div>
              </>}
            </div>
          </div>
          {/* <div className="icon-sub-menu">
            <div className="itens">
              <a href="/?esporte=futebol" className="item selected">
                <div className="title">
                  <p>Futebol</p>
                </div>
                <div className="img futebol"></div>
                <div className="marker"></div>
              </a>
              <a href="/?esporte=basquete" className="item">
                <div className="title">
                  <p>Basquete</p>
                </div>
                <div className="img basquete"></div>
                <div className="marker"></div>
              </a>
              <a href="/?esporte=boxe" className="item">
                <div className="title">
                  <p>Lutas</p>
                </div>
                <div className="img boxe"></div>
                <div className="marker"></div>
              </a>
              <a href="/?esporte=hoquei" className="item">
                <div className="title">
                  <p>Hóquei</p>
                </div>
                <div className="img hoquei"></div>
                <div className="marker"></div>
              </a>
              <a href="/?esporte=voleibol" className="item">
                <div className="title">
                  <p>Vôleibol</p>
                </div>
                <div className="img voleibol"></div>
                <div className="marker"></div>
              </a><a href="/?esporte=tenis" className="item">
                <div className="title">
                  <p>Tênis</p>
                </div>
                <div className="img tenis"></div>
                <div className="marker"></div>
              </a>
              <a href="/?esporte=league-of-legends" className="item">
                <div className="title">
                  <p>E-Sports</p>
                </div>
                <div className="img league-of-legends"></div>
                <div className="marker"></div>
              </a>
            </div>
          </div> */}

          <div className="scroll-menu__tree submenu-bar__secondary isEsportes">
            <ul className="submenu-bar__secondary__scroll">
              <li className="submenu-bar__sport">
                <a href="/sports/soccer">
                  <span className="name">Futebol</span>
                  <span className="icon icons-sports-src--static--icons--sports--white--futebol_24px icons-sports-src--static--icons--sports--white--futebol_24px--dims"></span>
                </a>
              </li>
              <li className="submenu-bar__sport">
                <a href="/esportes/basquete">
                  <span className="name">Basquete</span>
                  <span className="icon icons-sports-src--static--icons--sports--white--basquete_24px icons-sports-src--static--icons--sports--white--basquete_24px--dims"></span>
                </a>
              </li>
              <li className="submenu-bar__sport">
                <a href="/esportes/lutas">
                  <span className="name">Lutas</span>
                  <span className="icon icons-sports-src--static--icons--sports--white--boxe_24px icons-sports-src--static--icons--sports--white--boxe_24px--dims"></span>
                </a>
              </li>
              <li className="submenu-bar__sport ">
                <a href="/esportes/hoquei">
                  <span className="name">Hóquei</span>
                  <span className="icon icons-sports-src--static--icons--sports--white--hoquei_24px icons-sports-src--static--icons--sports--white--hoquei_24px--dims"></span>
                </a>
              </li>
              <li className="submenu-bar__sport ">
                <a href="/esportes/voleibol">
                  <span className="name">Voleibol</span>
                  <span className="icon icons-sports-src--static--icons--sports--white--voleibol_24px icons-sports-src--static--icons--sports--white--voleibol_24px--dims"></span>
                </a>
              </li>
              <li className="submenu-bar__sport ">
                <a href="/esportes/tenis">
                  <span className="name">Tênis</span>
                  <span className="icon icons-sports-src--static--icons--sports--white--tenis_24px icons-sports-src--static--icons--sports--white--tenis_24px--dims"></span>
                </a>
              </li>
              <li className="submenu-bar__sport ">
                <a href="/esportes/e-sports">
                  <span className="name">E-Sports</span>
                  <span className="icon icons-sports-src--static--icons--sports--white--league-of-legends_24px icons-sports-src--static--icons--sports--white--league-of-legends_24px--dims"></span>
                </a>
              </li>
            </ul>
          </div>
        </header>
      )

    return (
      <header>
        <div className={classnames('menu-bar', {'is-esportes': history.location.pathname === '/', 'is-loterias': history.location.pathname === '/loterias' })}>
          <div className={classnames('menu-bar__wrapper full-container only-jogos', {'menu-bar__wrapper--logged': user})}>
            <Link className="menu-logo" to="/">
              <img className="menu-logo__img" src={settings.logo} alt="Logo Betsul" style={{ marginLeft: '1.6rem' }} />
            </Link>
            <div className="menu-bar__sections">
              {/* history */}
              <Link className={classnames('menu-bar__section__item menu-bar__section__item--esportes', {'is-active': history.location.pathname === '/' })} to="/">
                Esportes
              </Link>
              <Link className={classnames('menu-bar__section__item menu-bar__section__item--loterias', {'is-active': history.location.pathname === '/loterias'})} to="/loterias">
                Loterias
              </Link>
            </div>
            <div className="search-bar">
              <input type="text" className="search-bar__input" placeholder="PROCURAR" />
            </div>
            {renderUserActions()}
            <div className="menu-bar__actions">
              {renderActions()}
            </div>
          </div>
        </div>
        <div className="submenu-bar is-esportes">
          <div className="submenu-bar__wrap">
            {/* <button className="submenu-bar__control submenu-bar__control--prev is-disabled">
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 320 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                <path d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"></path>
              </svg>
            </button>
            <button className="submenu-bar__control submenu-bar__control--next">
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 320 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
              </svg>
            </button> */}
            <div className="submenu-bar__primary">
              <a className="submenu-bar__primary-item" href="/aovivo">
                <span className="icon icons-menu-src--static--icons--menu--ao_vivo-white icons-menu-src--static--icons--menu--ao_vivo-white--dims"></span>
                <span>Ao Vivo</span>
              </a>
              <a className="submenu-bar__primary-item" href="/promocoes">
                <span className="icon icons-menu-src--static--icons--menu--promocoes-tag-white icons-menu-src--static--icons--menu--promocoes-tag-white--dims"></span>
                <span>Promoções</span>
              </a>
              <a className="submenu-bar__primary-item" href="/resultados">
                <span className="icon icons-menu-src--static--icons--menu--resultados-white icons-menu-src--static--icons--menu--resultados-white--dims"></span>
                <span>Resultados</span>
              </a>
              <a className="submenu-bar__primary-item submenu-bar__primary-item--noticias" href="/aovivo?esporte=video">
                <span className="icon-esporte icons-menu-src--static--icons--menu--noticias-white icons-menu-src--static--icons--menu--noticias-white--dims"></span>
                <span>Video</span>
              </a>
            </div>
            <div className="submenu-bar__secondary">
              <ul className="submenu-bar__secondary__scroll">
                <li className="submenu-bar__sport submenu-bar__sport--futebol">
                  <a href="/sports/soccer">
                    <span className="name">Futebol</span>
                    <span className="icon icons-sports-src--static--icons--sports--white--futebol_24px icons-sports-src--static--icons--sports--white--futebol_24px--dims"></span>
                  </a>
                </li>
                <li className="submenu-bar__sport submenu-bar__sport--basquete">
                  <a href="/esportes/basquete">
                    <span className="name">Basquete</span>
                    <span className="icon icons-sports-src--static--icons--sports--white--basquete_24px icons-sports-src--static--icons--sports--white--basquete_24px--dims"></span>
                  </a>
                </li>
                <li className="submenu-bar__sport submenu-bar__sport--mma-ufc">
                  <a href="/esportes/mma-ufc">
                    <span className="name">Lutas</span>
                    <span className="icon icons-sports-src--static--icons--sports--white--boxe_24px icons-sports-src--static--icons--sports--white--boxe_24px--dims"></span>
                  </a>
                </li>
                <li className="submenu-bar__sport submenu-bar__sport--hoquei">
                  <a href="/esportes/hoquei">
                    <span className="name">Hóquei</span>
                    <span className="icon icons-sports-src--static--icons--sports--white--hoquei_24px icons-sports-src--static--icons--sports--white--hoquei_24px--dims"></span>
                  </a>
                </li>
                <li className="submenu-bar__sport submenu-bar__sport--voleibol">
                  <a href="/esportes/voleibol">
                    <span className="name">Voleibol</span>
                    <span className="icon icons-sports-src--static--icons--sports--white--voleibol_24px icons-sports-src--static--icons--sports--white--voleibol_24px--dims"></span>
                  </a>
                </li>
                <li className="submenu-bar__sport submenu-bar__sport--tenis">
                  <a href="/esportes/tenis">
                    <span className="name">Tênis</span>
                    <span className="icon icons-sports-src--static--icons--sports--white--tenis_24px icons-sports-src--static--icons--sports--white--tenis_24px--dims"></span>
                  </a>
                </li>
                <li className="submenu-bar__sport submenu-bar__sport--league-of-legends">
                  <a href="/esportes/league-of-legends">
                    <span className="name">E-Sports</span>
                    <span className="icon icons-sports-src--static--icons--sports--white--league-of-legends_24px icons-sports-src--static--icons--sports--white--league-of-legends_24px--dims"></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <nav className="nav">
          <div className="nav-container container">
            <ul className="nav__list">
              <li>
                <div className="button-tab">
                  <a href="/aovivo" className="button-tab__title callout dt020">Ao Vivo</a>
                </div>
              </li>
              <li>
                <div className="button-tab">
                  <a href="/resultados" className="button-tab__title callout dt020">Resultados</a>
                </div>
              </li>
              <li>
                <div className="button-tab">
                  <a href="https://stats.betsul.com/spbr2/pt" className="button-tab__title callout dt020">Estatísticas</a>
                </div>
              </li>
              <li>
                <div className="button-tab">
                  <a href="/noticias" className="button-tab__title callout dt020">Notícias</a>
                </div>
              </li>
              <li>
                <div className="button-tab">
                  <a href="/promocoes" className="button-tab__title callout dt020">Promoções</a>
                </div>
              </li>
            </ul>
            <p className="caption-3 dt020">
              {date.toLocaleDateString()} · {date.toLocaleTimeString()} UTC -03:00
            </p>
          </div>
        </nav> */}
      </header>
    )
  }

  return (
    <>
      {renderContent()}
      <LoginModal
        show={showLogin}
        apiUrl={apiUrl}
        isMobile={isMobile}
        loadUser={loadUser}
        onHide={() => setShowLogin(false)}
      />
      <SignupModal
        show={showSignup}
        apiUrl={apiUrl}
        isMobile={isMobile}
        onHide={() => setShowSignup(false)}
        onSubmit={() => hideSignupModal()}
      />
    </>
  )
}
