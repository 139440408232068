import { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import io from 'socket.io-client';
import { format } from 'date-fns';
import classnames from 'classnames';

import Cart from '../components/layout/Cart';
import { generateMatch, generateOdds } from '../utils'

export default function Match({ apiUrl, socketUrl, settings, user, isMobile, betAmount, onChangeBetAmount, calculatePrize, finishBet, selectedOdds, handleOddClick, onCleanOdds }) {
  const history = useHistory()
  const { match_id } = useParams()
  const routeMatch = useRouteMatch('/sports/soccer/:championship/:match_id/live')
  const isLive = routeMatch !== null
  const [socket, setSocket] = useState(null)
  const [match, setMatch] = useState(null)
  const [odds, setOdds] = useState([])
  const [matchTime, setMatchTime] = useState('fullTime')

  const matchTimes = [
    { key: 'fullTime', text: '90 min' },
    { key: 'firstTime', text: '1º T' },
    { key: 'secondTime', text: '2º T' },
    { key: 'players', text: 'Jogadores' },
  ]

  function activeClass(key) {
    return (matchTime === key) ? 'selected' : ''
  }

  useEffect(() => {
    if (isLive) {
      const _socket = io(socketUrl, { path: '/api/live/socket.io' })
      _socket.on('live_data', (data) => {
        const m = data.filter(m => +m.id_partida === +match_id)[0]
        const _match = generateMatch(m, settings, true)
        setMatch(_match)
        const _odds = generateOdds(_match, true, Object.values(settings.categorias))
        setOdds(_odds)
      }).on('connect_error', (reason) => {
        console.error('connect_error', reason)
      }).on('disconnect', (reason) => {
        console.error('disconnect', reason)
      })

      setSocket(_socket)
      return () => _socket.close()
    }
  }, [isLive, socketUrl, match_id, settings])

  useEffect(() => {
    if (!isLive) {
      const formData = new FormData();
      formData.append('chave', match_id)
      formData.append('tipo', 'partida')
      formData.append('data', '')
      formData.append('principal', 0)

      fetch(`${apiUrl}/api/jogos`, {
        method: 'POST',
        body: formData
      })
        .then((res) => res.json())
        .then((data) => {
          setMatch(data[0])
        })
    }
  }, [isLive, apiUrl, match_id])

  useEffect(() => {
    if (socket === null) return

    const updateMatch = (data) => {
      if (data.tempo_jogo >= settings.tempo_bloqueio_ao_vivo)
        return

      // const _match = generateMatch(data, settings, true)
      // setMatch(_match)
      // const _odds = generateOdds(_match, _match, true, Object.values(settings.categorias))
      // setOdds(_odds)
    }

    const removeMatch = (data) => {
      if (+data.id_partida === +match_id) {
        console.log(history)
        // history.replace('/')
      }
    }

    socket.on('live_match', updateMatch)
    socket.on('remove_match', removeMatch)

    return () => {
      socket.off('live_match', updateMatch)
      socket.off('remove_match', removeMatch)
    }
  }, [socket, settings, match_id, history])

  useEffect(() => {
    if (match && !isLive) {
      const _odds = generateOdds(match)
      setOdds(_odds)
    }
  }, [match, isLive])

  if (!match) {
    return (<p>Carregando...</p>)
  }

  const renderOdds = () => {
    if (matchTime === 'players') {
      if (!match.jogadores) {
        return (
          <p>Sem cotações de jogadores para o jogo</p>
        )
      } else {
        const types = ['first', 'last', 'anytime']

        return (
          Object.keys(odds[matchTime]).map(category_id => (
            <div key={category_id} className="evento subeventos-dados--item resultados-template">
              <div className="section-title">
                <div className="title-2 n150">
                  <h2>{odds[matchTime][category_id].categoria}</h2>
                </div>
              </div>
              <div className="subeventos-dados--list-tipoaposta shadow-on-hover">
                {Object.values(odds[matchTime][category_id].odds).map(odd => (
                  <div key={odd.first.jogador_id} className="subeventos-dados--tipoaposta">
                    <h3 className="subeventos-dados--tipoaposta__title">
                      {odd.first.jogador}
                    </h3>
                    <div className="subeventos-dados--odds">
                      {types.map(type => {
                        const isDisabled = +odd[type].cotacao <= 1.0

                        if (odd[type]) {
                          return (
                            <div className="subevento" key={odd[type].id_aposta}>
                              <small>{odd[type].tipo}</small>
                              <button
                                className={classnames('button-odd', { 'button-odd--disabled': isDisabled, 'button-odd--active': selectedOdds.find(item => item.match.id_partida === match.id_partida && item.odd.id_tipo === odd.id_tipo && (!!odd.jogador_id ? item.odd.jogador_id === odd.jogador_id : true)) })}
                                title={`${odd[type].tipo} ${odd[type].cotacao}`}
                                onClick={() => handleOddClick(match, odd)}
                                disabled={isDisabled}
                              >
                                <span className={classnames('button-odd-default', { 'cotacao-zero': isDisabled })}>
                                  <span className="button-odd-default__title truncate subhead dt030"> </span>
                                  <span className="button-odd-default__info subhead dt010">
                                    {isDisabled ? <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"></path>
                                    </svg> : odd[type].cotacao.toFixed(2)}
                                  </span>
                                </span>
                              </button>
                            </div>
                          );
                        }

                        return null
                      })}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))
        );
      }
    }

    if (!odds || !odds[matchTime])
      return <></>

    return (
      odds[matchTime].map(category => (
        <div key={category.id} className="evento subeventos-dados--item resultados-template">
          <div className="section-title">
            <div className="title-2 n150">
              <h2>{category.name}</h2>
            </div>
          </div>
          <div className="subeventos-dados--list-tipoaposta shadow-on-hover">
            {category.odds.map(odd => {
              const isDisabled = +odd.cotacao <= 1.0

              return (
                <div key={odd.id_tipo} className="subeventos-dados--tipoaposta">
                  <h3 className="subeventos-dados--tipoaposta__title">
                    {odd.tipo}
                  </h3>
                  <div className="subeventos-dados--odds">
                    <div className="subevento">
                      <button
                        className={classnames('button-odd', { 'button-odd--disabled': isDisabled, 'button-odd--active': selectedOdds.find(item => item.match.id_partida === match.id_partida && item.odd.id_tipo === odd.id_tipo) })}
                        title={`${odd.tipo} ${odd.cotacao}`}
                        onClick={() => handleOddClick(match, odd)}
                        disabled={isDisabled}
                      >
                        <span className={classnames('button-odd-default', { 'cotacao-zero': isDisabled })}>
                          <span className="button-odd-default__title truncate subhead dt030"> </span>
                          <span className="button-odd-default__info subhead dt010">
                            {isDisabled ? <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                              <path d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"></path>
                            </svg> : (+odd.cotacao).toFixed(2)}
                          </span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      ))
    )
  }

  if (isMobile) {
    return (
      <>
        <Helmet>
          <title>{settings.nome}</title>
          <meta name="keywords" content={settings.chaves} />
          <meta name="description" content={settings.descricao} />
          <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.11.0/css/all.css" />
        </Helmet>
        <div className="wrap-content" style={{ minHeight: '120vh' }}>
          <div>
            <div className="new-layout">
              <div className="home container">
                <div className="subeventos-header--title">
                  <div className="info-header">
                    <h1 className="subeventos-header--title--confronto">
                      {match.mandante} x {match.visitante} - {match.campeonato}
                    </h1>
                  </div>
                </div>
                <div className="subeventos-header">
                  <div className="subeventos-header--banner">
                    <div className="subeventos-header-banner--container">
                      <div className="subeventos-header-banner--time-container">
                        <span className="subeventos-header-banner--time casa truncate">
                          {match.mandante}
                        </span>
                        <span className="subeventos-header-banner--time-hora">
                          {isLive ? <>
                            {match.tempo_jogo} - {match.tempo}
                          </>
                            : format(new Date(match.data), "d / MMM - HH'h'mm")}
                        </span>
                        <span className="subeventos-header-banner--time fora truncate">
                          {match.visitante}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {!isLive ?
                  <ul className="weekday-filter expand">
                    {matchTimes.map(time => (
                      <li key={time.key} className={['weekday-filter__item', activeClass(time.key)].join(' ')} onClick={() => setMatchTime(time.key)}>
                        <button>{time.text}</button>
                      </li>
                    ))}
                  </ul>
                  : null}
                <div className="subeventos-dados">
                  {renderOdds()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>{settings.nome}</title>
        <meta name="keywords" content={settings.chaves} />
        <meta name="description" content={settings.descricao} />
        <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.11.0/css/all.css" />
      </Helmet>
      <div>
        <div className="new-layout">
          <div className="menu-left__wrap">
            <div className="menu-left">
              <div className="menu-left__item menu-left__item--highlight">
                <a className="menu-left__item__action" href="/como-funciona">
                  <span className="menu-left__item__action__title">Como funciona</span>
                </a>
              </div>
              <ul className="menu-left__group">
                <li className="menu-left__item menu-left__item--grouphead">
                  <div className="menu-left__item__action">
                    <span className="menu-left__item__action__title">Esportes</span>
                  </div>
                </li>
                <li className="menu-left__item menu-left__item--groupitem  level-1" data-id="4,0">
                  <div className="sport-icon-menu sport-icon-menu__futebol"></div>
                  <a className="menu-left__item__action" href="/sports/soccer">
                    <span className="menu-left__item__action__title">Futebol</span>
                  </a>
                  <div className="menu-left__item__toggle">
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                    </svg>
                  </div>
                </li>
                <li className="menu-left__item menu-left__item--groupitem  level-1" data-id="4,3">
                  <div className="sport-icon-menu sport-icon-menu__basquete"></div>
                  <a className="menu-left__item__action" href="/esportes/basquete">
                    <span className="menu-left__item__action__title">Basquete</span>
                  </a>
                  <div className="menu-left__item__toggle">
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                    </svg>
                  </div>
                </li>
                <li className="menu-left__item menu-left__item--groupitem  level-1" data-id="4,5">
                  <div className="sport-icon-menu sport-icon-menu__boxe"></div>
                  <a className="menu-left__item__action" href="/esportes/boxe">
                    <span className="menu-left__item__action__title">Lutas</span>
                  </a>
                  <div className="menu-left__item__toggle">
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                    </svg>
                  </div>
                </li>
                <li className="menu-left__item menu-left__item--groupitem  level-1" data-id="4,9">
                  <div className="sport-icon-menu sport-icon-menu__hoquei"></div>
                  <a className="menu-left__item__action" href="/esportes/hoquei">
                    <span className="menu-left__item__action__title">Hóquei</span>
                  </a>
                  <div className="menu-left__item__toggle">
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                    </svg>
                  </div>
                </li>
                <li className="menu-left__item menu-left__item--groupitem  level-1" data-id="4,2">
                  <div className="sport-icon-menu sport-icon-menu__voleibol"></div>
                  <a className="menu-left__item__action" href="/esportes/voleibol">
                    <span className="menu-left__item__action__title">Vôleibol</span>
                  </a>
                  <div className="menu-left__item__toggle">
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                    </svg>
                  </div>
                </li>
                <li className="menu-left__item menu-left__item--groupitem  level-1" data-id="4,4">
                  <div className="sport-icon-menu sport-icon-menu__tenis"></div>
                  <a className="menu-left__item__action" href="/esportes/tenis">
                    <span className="menu-left__item__action__title">Tênis</span>
                  </a>
                  <div className="menu-left__item__toggle">
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                    </svg>
                  </div>
                </li>
                <li className="menu-left__item menu-left__item--groupitem  level-1" data-id="4,12">
                  <div className="sport-icon-menu sport-icon-menu__league-of-legends"></div>
                  <a className="menu-left__item__action" href="/esportes/league-of-legends">
                    <span className="menu-left__item__action__title">E-Sports</span>
                  </a>
                  <div className="menu-left__item__toggle">
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                    </svg>
                  </div>
                </li>
              </ul>
              <ul className="menu-left__group">
                <li className="menu-left__item menu-left__item--grouphead">
                  <div className="menu-left__item__action">
                    <span className="menu-left__item__action__title">Institucionais</span>
                  </div>
                </li>
                <li className="menu-left__item menu-left__item--groupitem"><div className="sport-icon-menu sport-icon-menu__quem_somos"></div><a className="menu-left__item__action" href="/quem-somos"><span className="menu-left__item__action__title">Quem somos</span></a></li>
                <li className="menu-left__item menu-left__item--groupitem"><div className="sport-icon-menu sport-icon-menu__ajuda"></div><a className="menu-left__item__action" href="/ajuda"><span className="menu-left__item__action__title">Ajuda</span></a></li>
                <li className="menu-left__item menu-left__item--groupitem"><div className="sport-icon-menu sport-icon-menu__regras"></div><a className="menu-left__item__action" href="/regras"><span className="menu-left__item__action__title">Regras</span></a></li>
              </ul>
            </div>
          </div>
          <div className="home container">
            <div className="subeventos-header--title">
              <div className="info-header">
                <h1 className="subeventos-header--title--confronto">
                  {match.mandante} x {match.visitante} - {match.campeonato}
                </h1>
              </div>
            </div>
            {/* <div className="subeventos__stream">
              <div className="subeventos__stream__tabs-menu">
                <button className="subeventos__stream__tabs-menu__item subeventos__stream__tabs-menu__item--details disabled">
                  <span>Detalhes</span>
                </button>
                <button className="subeventos__stream__tabs-menu__item subeventos__stream__tabs-menu__item--stats">
                  <span>Estatísticas</span>
                </button>
              </div>
              <div className="subeventos__stream__tabs"></div>
            </div> */}
            <div className="subeventos-header">
              <div className="subeventos-header--banner">
                <div className="subeventos-header-banner--container">
                  <div className="subeventos-header-banner--time-container">
                    <span className="subeventos-header-banner--time casa truncate">
                      {match.mandante}
                    </span>
                    <span className="subeventos-header-banner--time-hora">
                      {isLive ? <>
                        {match.tempo_jogo} - {match.tempo}
                      </>
                        : format(new Date(match.data), "d / MMM - HH'h'mm")}
                    </span>
                    <span className="subeventos-header-banner--time fora truncate">
                      {match.visitante}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {!isLive ?
              <ul className="weekday-filter expand">
                {matchTimes.map(time => (
                  <li key={time.key} className={['weekday-filter__item', activeClass(time.key)].join(' ')} onClick={() => setMatchTime(time.key)}>
                    <button>{time.text}</button>
                  </li>
                ))}
              </ul>
              : null}
            <div className="subeventos-dados">
              {renderOdds()}
            </div>
          </div>
          <Cart
            settings={settings}
            user={user}
            betAmount={betAmount}
            onChangeBetAmount={onChangeBetAmount}
            calculatePrize={calculatePrize}
            finishBet={finishBet}
            odds={selectedOdds}
            onRemoveOdd={handleOddClick}
            onCleanOdds={onCleanOdds}
          />
        </div>
      </div>
    </>
  )
}
