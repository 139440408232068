import { useState } from 'react'
import { Link } from "react-router-dom"

import Cart from '../modals/Cart';
import { ReactComponent as Login } from '../../assets/icons/menu/login.svg'
import { ReactComponent as Signup } from '../../assets/icons/menu/signup.svg'
import { ReactComponent as MarketCart } from '../../assets/icons/menu/carrinho.svg'

export default function FloatMenu({ settings, user, betAmount, onChangeBetAmount, calculatePrize, finishBet, selectedOdds, onRemoveOdd, onCleanOdds, showLogin, showSignup }) {
  const [showCart, setShowCart] = useState(false)

  const renderContent = () => {
    if (user)
      return (
        <>
          <li>
            <Link className="float-menu__button float-menu__button--light" to="/opcoes/deposito">
              <div className="float-menu__value truncate">R$ {user.saldo}</div>
              <div className="float-menu__label truncate">Depositar</div>
            </Link>
          </li>
          <li>
            <Link className="float-menu__button" to="/opcoes/apostas">
              <div className="float-menu__icon icon-menu-apostas"></div>
              <div className="float-menu__label truncate">Apostas</div>
            </Link>
          </li>
        </>
      )

    return (
      <>
        <li>
          <div className="float-menu__button" onClick={() => showLogin()}>
            <div className="float-menu__icon">
              <Login />
            </div>
            <div className="float-menu__label truncate">Login</div>
          </div>
        </li>
        <li>
          <div className="float-menu__button float-menu__button--light" onClick={() => showSignup()}>
            <div className="float-menu__icon">
              <Signup />
            </div>
            <div className="float-menu__label truncate">Cadastrar</div>
          </div>
        </li>
      </>
    )
  }

  return (
    <>
      <nav className="float-menu">
        <ul>
          {renderContent()}
          {selectedOdds.length > 0 && <li>
            <div className="float-menu__button" onClick={() => setShowCart(true)}>
              <div className="float-menu__icon">
                <span className="float-menu__icon__badge">{selectedOdds.length}</span>
                <MarketCart />
              </div>
              <div className="float-menu__label truncate">Carrinho</div>
            </div>
          </li>}
        </ul>
      </nav>
      <Cart
        show={showCart}
        onHide={() => setShowCart(false)}
        settings={settings}
        user={user}
        betAmount={betAmount}
        onChangeBetAmount={onChangeBetAmount}
        calculatePrize={calculatePrize}
        finishBet={finishBet}
        selectedOdds={selectedOdds}
        onRemoveOdd={onRemoveOdd}
        onCleanOdds={onCleanOdds}
      />
    </>
  )
}
